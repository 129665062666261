import { useEffect, useRef, useState } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowDownSvg } from 'src/assets/icons/arrow-down.svg';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import PageWrapper from 'src/components/Page/PageWrapper';
import Tabs from 'src/components/Tabs';
import LocationsTab from 'src/pages/Regions/tabs/Locations';
import RegionsTab from 'src/pages/Regions/tabs/Regions';
import { actionCb } from 'src/utils/actions';
import { notifyPrimary } from 'src/utils/notification';
import { getAllUrlParams } from 'src/utils/routes';
import classes from './Regions.module.scss';
import { CREATE_LINK_MAPPING, REGION_TAB_KEYS } from './constants';
import { useActions, useIndexData } from './selectorData';

const Regions = () => {
  const location = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const notificationAlertRef = useRef(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(REGION_TAB_KEYS.REGIONS);
  const isLocation = activeTab === REGION_TAB_KEYS.LOCATIONS;
  const { settings } = useIndexData();
  const { deleteRegion, getRegions, syncRemoveRegions } = useActions();
  // const isEnableRegions = !!settings.find((obj) => obj.key === 'enable_regions')
  //   ?.value;
  const isEnableRegions = true;
  const breadcrumbs = [
    {
      label: 'Regions & Locations',
    },
  ];
  const notify = (type, title, description, settings) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings,
    });
  };
  const reloadRegions = () => {
    getRegions({});
  };
  const handleDeleteRegion = (deleteId, cb, onClose) => {
    deleteRegion(
      deleteId,
      actionCb(
        () => {
          syncRemoveRegions(deleteId);
          notify('success', 'Success', 'Region deleted successfully');
          if (cb) cb();
          onClose();
        },
        () => {
          notify('error', 'Failed', 'Region deleting failed');
          if (cb) cb();
        }
      )
    );
  };
  const REGION_TAB = {
    key: REGION_TAB_KEYS.REGIONS,
    title: 'Regions',
    content: (
      <RegionsTab
        isEnableRegions={isEnableRegions}
        onDelete={handleDeleteRegion}
      />
    ),
  };
  const LOCATION_TAB = {
    key: REGION_TAB_KEYS.LOCATIONS,
    title: 'Locations',
    content: <LocationsTab notify={notify} />,
  };
  const items = isEnableRegions ? [REGION_TAB, LOCATION_TAB] : [LOCATION_TAB];

  useEffect(() => {
    if (
      urlParams?.createSuccess === 'true' ||
      urlParams?.updateSuccess === 'true'
    ) {
      notify(
        'success',
        'Success',
        `Region ${
          urlParams?.createSuccess === 'true' ? 'created' : 'updated'
        } successfully`
      );
      navigate('/regions');
    }
    const tDefaultTab = urlParams.tab;
    if (tDefaultTab) {
      setActiveTab(tDefaultTab);
      if (tDefaultTab === REGION_TAB_KEYS.LOCATIONS) {
        // Load locations
      } else {
        reloadRegions();
      }
    } else {
      reloadRegions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <PageWrapper className={classes.wrapper}>
        <Breadcrumb items={breadcrumbs} />
        <Tabs
          items={items}
          activeTab={isEnableRegions ? activeTab : REGION_TAB_KEYS.LOCATIONS}
          onChange={(val) => {
            setActiveTab(val);
            navigate(`/regions?tab=${val}`);
          }}
          hideHeaderBorder={!isEnableRegions}
          only1Header={!isEnableRegions}
          headerExtra={
            <Button
              variant="primary"
              onClick={() => {
                if (CREATE_LINK_MAPPING[activeTab]) {
                  navigate(CREATE_LINK_MAPPING[activeTab]);
                }
              }}
              type="button"
              fontSize="sm"
              rightIcon={<ArrowDownSvg />}
              disabled={!isEnableRegions}
            >
              Create New
            </Button>
          }
        />
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default Regions;
