/* eslint-disable no-console */
// eslint-disable-next-line sort-imports
import { getIncomers, getOutgoers, getConnectedEdges } from '@xyflow/react';
export const runFlowOnProcessId = (nodes, edges, runData) => {
  var processed = [];
  const evalWithVariables = (func, vars) => {
    var code = '';
    try {
      var varString = '';

      Object.keys(vars).map((key) => {
        if (key.indexOf('_question_def') === -1) {
          var val = vars[key] || 'undefined';
          if (
            (!parseFloat(val) && val !== 'undefined') ||
            ('/' + val).indexOf('/') > -1
          )
            val = "'" + val + "'";
          varString += 'var ' + key + ' = ' + val + ';\n';
        }
      });
      code = varString + '; ' + func.replace(/´/g, "'") + ' ;';
      // console.log(code);
      const Util = {
        SetValue: (field, value) => {
          runData[field] = value;
          // alert(field + ' = ' + value)
        },
        RaiseFlag(form, flag) {
          if (!runData[form.trim().replace(/ /g, '_') + '_FLAGS'])
            runData[form.trim().replace(/ /g, '_') + '_FLAGS'] = [];
          if (
            runData[form.trim().replace(/ /g, '_') + '_FLAGS'].indexOf(flag) ===
            -1
          )
            runData[form.trim().replace(/ /g, '_') + '_FLAGS'].push(flag);
        },
        RemoveFlag(form, flag) {
          if (!runData[form.trim().replace(/ /g, '_') + '_FLAGS'])
            runData[form.trim().replace(/ /g, '_') + '_FLAGS'] = [];
          runData[form.trim().replace(/ /g, '_') + '_FLAGS'] = runData[
            form.trim().replace(/ /g, '_') + '_FLAGS'
          ].filter((f) => f !== flag);
        },
      };
      var result = eval(code);
      // console.log(result)
      return result;
    } catch (e) {
      console.log(
        'eval failed: ' +
          e.message +
          '\n - in script: \n' +
          code +
          '\n - run data: \n'
      );
      console.log(runData);
      return false;
    }
  };

  const recourse = (node, ind) => {
    if (processed.indexOf(node) > -1) {
      console.log('BLOCKED DUP PROC');
      return;
    }
    // console.log(node.data?.label + ' trying')

    if (node.data.subType == 'dnode_form') {
      ind += 10;
    } else ind++;

    if (!node.analysis.order || node.analysis.order < ind) {
      node.analysis.order = ind;
    }
    // node.analysis.passedAllConditions = true
    processed.push(node);
    var passed = true;
    if (node.data.subType == 'dnode_action' && node.data.js_code) {
      // execute action
      passed = evalWithVariables(node.data.js_code, { ...runData });
    }
    if (node.data.subType == 'dnode_cond' && node.data.js_code) {
      // evaluate condition
      passed = evalWithVariables(node.data.js_code, { ...runData });
    }
    if (!passed && !node.analysis.passed) {
      // console.log(node.data?.label + ' failed')
      node.analysis.failed = true;
      node.analysis.passed = false;
      return;
    }
    node.analysis.failed = false;
    node.analysis.passed = true;

    const outgoers = getOutgoers(node, nodes, edges);

    outgoers.forEach((outgoingNode) => {
      recourse(outgoingNode, ind);
    });
  };

  // const forms = nodes.filter((node) =>
  //     node.data.subType === 'dnode_form'
  // )
  const firstNodes = nodes.filter((node) => {
    //node.data.label=edges.filter((edge: CustomEdgeType) => edge.target === node.id).length
    node.key = node.data.label.split('(')[0].trim().replace(/ /g, '_');
    node.analysis = { passed: false };
    const incomers = getIncomers(node, nodes, edges);
    // debugger
    return incomers.length == 0;
    // return edges.filter((edge) => edge.target === node.id).length === 0
  });

  firstNodes.map((firstNode) => {
    recourse(firstNode, 0);
  });

  var forms = nodes
    .filter(
      (node) => node.data.subType === 'dnode_form' && node.analysis.passed
    )
    // .sort((a, b) => {
    //   // console.log(a.analysis.order + ' > ' + b.analysis.order);
    //   return a.analysis.order - b.analysis.order; // Numerical comparison
    // })
    .sort((a, b) => a.position.y - b.position.y);
  // console.log(forms)
  return { _forms: forms, _runData: runData };
};
