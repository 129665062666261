import { useEffect, useRef } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import classes from './Workflow.module.scss';
import Flow from './components/Flow';
import { useActions, useIndexData } from './selectorData';

const Workflow = () => {
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const workflowId = Number(params.id || 0);
  // const { getWorkflow } = useActions();
  const { workflow, workflowLoading } = useIndexData();
  const breadcrumbs = [
    {
      link: '/workflow',
      label: 'Workflow',
    },
    {
      label: workflow.name,
    },
  ];

  useEffect(() => {
    // getWorkflow(workflowId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {workflowLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <div className={classes.workflows}>
          <Flow />
        </div>
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default Workflow;
