import cn from 'classnames';
import classes from './Alert.module.scss';

const Alert = ({ onClick, className }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div>
        <span>
          Don’t need regions? Turn them off by selecting the option to the
          right. This action will hide the Regions tab and can be adjusted in
          settings
        </span>
        <span onClick={onClick}>Turn Regions Off</span>
      </div>
    </div>
  );
};

export default Alert;
