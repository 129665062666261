import Request from './request';

export const apiGetCompanySettings = async () => {
  return Request.call({
    url: '/api/company_settings',
    method: 'GET',
  });
};
export const apiUpdateCompanySettings = async (data) => {
  return Request.call({
    url: '/api/company_settings',
    method: 'POST',
    data,
  });
};
