export const getFullAddress = (obj, enterNewLine = false) => {
  const address = [
    obj?.address_1,
    obj?.address_2,
    enterNewLine ? '\n' : '',
    obj?.city,
  ]
    .filter((v) => !!v)
    .join(enterNewLine ? '' : ' ');
  const address1 = [obj?.state, obj?.zip].filter((v) => !!v).join(' ');
  return [address, address1].filter((v) => !!v).join(', ');
};
