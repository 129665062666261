export const WEEK_DAY = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};
export const WEEK_DAYS = [
  WEEK_DAY.MONDAY,
  WEEK_DAY.TUESDAY,
  WEEK_DAY.WEDNESDAY,
  WEEK_DAY.THURSDAY,
  WEEK_DAY.FRIDAY,
  WEEK_DAY.SATURDAY,
  WEEK_DAY.SUNDAY,
];
