import cn from 'classnames';
import React from 'react';
import { ModalBody, ModalHeader, Modal as ReactstrapModal } from 'reactstrap';
import classes from './Modal.module.scss';

const Modal = ({
  className,
  title,
  children,
  isOpen,
  toggle,
  hideClose,
  ...other
}) => {
  return (
    <ReactstrapModal
      isOpen={isOpen}
      toggle={toggle}
      className={cn(classes.wrapper, className)}
      fade={false}
      {...other}
    >
      {!!title && (
        <ModalHeader toggle={hideClose ? undefined : toggle}>
          {title}
        </ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
    </ReactstrapModal>
  );
};

export default Modal;
