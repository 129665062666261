import cn from 'classnames';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ReactComponent as ArrowDown1Svg } from 'src/assets/icons/arrow-down1.svg';
import classes from './Score.module.scss';

const Score = ({
  className,
  score,
  title,
  color,
  activeColor,
  issuesColor,
  width = 180,
  strokeWidth = 12,
  issues,
}) => {
  const hasIssues = Number(issues) >= 0;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.hasIssues]: hasIssues,
        },
        className
      )}
    >
      <div
        className={classes.circle}
        style={{
          height: `calc(${width / 2}px + 11px)`,
        }}
      >
        <CircularProgressbar
          value={score}
          strokeWidth={strokeWidth}
          circleRatio={0.5}
          styles={{
            ...buildStyles({
              pathColor: activeColor || '#82EB80',
              trailColor: color || '#ADBDBF',
            }),
            root: {
              transform: 'rotate(0.75turn)',
            },
          }}
        />
      </div>
      <div className={classes.info}>
        <span className={classes.title}>{title}</span>
        <span className={classes.score}>{score}</span>
      </div>
      {hasIssues && (
        <div className={classes.issues}>
          <div>
            <span style={{ background: issuesColor || '#FFDD9B' }}>
              <span>
                {issues} Issue{issues === 1 ? '' : 's'}
              </span>
              <ArrowDown1Svg />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Score;
