import { getUpdatedSettings } from 'src/utils/store';
import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/setting';

const initialState = {
  setting: {
    ...defaultObj,
  },
  updateSetting: {
    ...defaultObj,
  },
};

const SettingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_UPDATE_SETTINGS: {
      return {
        ...state,
        setting: {
          ...state.setting,
          data: {
            ...state.setting.data,
            data: getUpdatedSettings(
              state.setting.data.data,
              payload.key,
              payload.value
            ),
          },
        },
      };
    }
    case Types.GET_SETTING:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          setting: {
            ...prevState.setting,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          setting: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          setting: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_SETTING:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateSetting: {
            ...prevState.updateSetting,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateSetting: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateSetting: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default SettingReducer;
