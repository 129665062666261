export const getLocationPayload = (values) => {
  return {
    name: values?.name || '',
    address: {
      address_1: values?.streetAddress || '',
      address_2: values?.apt || '',
      city: values?.city || '',
      state: values?.state || '',
      zip: values?.zipCode || '',
    },
  };
};
export const getRegionPayload = (values) => {
  return {
    name: values?.name || '',
    description: values?.description || '',
    location_ids: (values?.addedLocations || []).map((obj) => obj.value),
  };
};
