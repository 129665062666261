import { apiGetCompanySettings, apiUpdateCompanySettings } from 'src/api';
import Types from '../types/setting';

export const syncUpdateSettings = (key, value) => (dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_SETTINGS,
    payload: { key, value },
  });
};

export const getSettings = (cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_SETTING,
    asyncCall: () => {
      return apiGetCompanySettings();
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const updateSettings = (data, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_SETTING,
    asyncCall: () => {
      return apiUpdateCompanySettings(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
