import { useController } from 'react-hook-form';
import Checkbox from './';

const FormCheckbox = ({ name, control, onChange, ...props }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <Checkbox
      {...props}
      {...field}
      error={error?.message}
      checked={!!field.value}
      id={name}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
          field.onChange(e);
        } else {
          field.onChange(e);
        }
      }}
    />
  );
};

export default FormCheckbox;
