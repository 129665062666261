import Request from './request';

export const apiGetRegions = async (params) => {
  return Request.call({
    url: '/api/regions',
    method: 'GET',
    params,
  });
};
export const apiGetRegionDetails = async (id) => {
  return Request.call({
    url: `/api/regions/${id}`,
    method: 'GET',
  });
};
export const apiCreateRegion = async (data) => {
  return Request.call({
    url: '/api/regions',
    method: 'POST',
    data,
  });
};
export const apiDeleteRegion = async (id) => {
  return Request.call({
    url: `/api/regions/${id}`,
    method: 'DELETE',
    data: {},
  });
};
