import cn from 'classnames';
import { InputGroup as ReactstrapInputGroup } from 'reactstrap';
import classes from './InputGroup.module.scss';

const InputGroup = ({ className, ...props }) => {
  return (
    <ReactstrapInputGroup
      className={cn(classes.wrapper, className)}
      {...props}
    />
  );
};

export default InputGroup;
