import cn from 'classnames';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classes from './Tooltip.module.scss';

export default class SimpleTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.tooltipRef = React.createRef();
  }

  render() {
    const { children, tooltipText, className, ...other } = this.props;

    return (
      <>
        <span ref={this.tooltipRef} className={cn(classes.wrapper, className)}>
          {children}
        </span>
        <UncontrolledTooltip delay={0} {...other} target={this.tooltipRef}>
          {tooltipText}
        </UncontrolledTooltip>
      </>
    );
  }
}
