export const LOCATION_DETAILS_TAB_KEYS = {
  SUB_SYSTEMS: 'SUB_SYSTEMS',
  EQUIPMENT: 'EQUIPMENT',
  EMERGENCY: 'EMERGENCY',
  STAFF_LIST: 'STAFF_LIST',
  CHEMICALS: 'CHEMICALS',
};
export const LOCATION_DETAILS_TABS = [
  {
    key: LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS,
    title: 'Sub-Systems',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.EQUIPMENT,
    title: 'Equipment',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.EMERGENCY,
    title: 'Emergency',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.STAFF_LIST,
    title: 'Staff List',
  },
  {
    key: LOCATION_DETAILS_TAB_KEYS.CHEMICALS,
    title: 'Chemicals',
  },
];
