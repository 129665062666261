import cn from 'classnames';
import { useState } from 'react';
import Button from 'src/components/Button';
import Filter from 'src/components/Filter';
import SearchInput from 'src/components/FormFields/Input/SearchInput';
import Table from 'src/components/Table';
import classes from './LocationDetailsTable.module.scss';

const LocationDetailsTable = ({ className, columns, title }) => {
  const [search, setSearch] = useState('');
  const [checkedRows, setCheckedRows] = useState([]);
  const data = [{ key: 1, id: 1 }];
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>{title}</span>
        <div>
          <SearchInput
            size="medium"
            placeholder="Search Locations"
            iconPosition="right"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Filter />
          <Button
            variant="primary"
            onClick={() => {}}
            fontSize="sm"
            size="medium"
          >
            Add New
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        checkable={{
          checkedRows,
          onChange: (cRows) => {
            setCheckedRows(cRows);
          },
        }}
      />
    </div>
  );
};

export default LocationDetailsTable;
