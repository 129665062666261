import cn from 'classnames';
import { ReactComponent as ArrowDown1Svg } from 'src/assets/icons/arrow-down1.svg';
import classes from './Filter.module.scss';

const Filter = ({ className }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span>Filter</span>
      <ArrowDown1Svg />
    </div>
  );
};

export default Filter;
