import cn from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'src/components/Loading';
import { DELETE_REGION_HAS_LOCATION_PREFIX } from 'src/helper/constants';
import Alert from 'src/pages/Regions/components/Alert';
import RegionCard from 'src/pages/Regions/components/Card';
import DeleteModal from 'src/pages/Regions/components/DeleteModal';
import EmptyState from 'src/pages/Regions/components/EmptyState';
import TurnOffModal from 'src/pages/Regions/components/TurnOffModal';
import classes from './Regions.module.scss';
import { useActions, useIndexData } from './selectorData';

const Regions = ({ isEnableRegions, onDelete }) => {
  const navigate = useNavigate();
  const [isTurnOff, setIsTurnOff] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const { updateSettings, syncUpdateSettings } = useActions();
  const { regions, regionsLoading } = useIndexData();
  const regionsList = regions?.data || [];
  const hasRegions = regions?.total > 0;

  return (
    <>
      <div className={cn(classes.wrapper)}>
        {regionsLoading && <Loading isPage />}
        <div>
          <span className={classes.description}>
            Select regions or locations to view incident score and information.
          </span>
        </div>
        {!hasRegions && !regionsLoading ? (
          <EmptyState
            entity="region"
            onCreate={() => {
              navigate('/regions/create-region');
            }}
          />
        ) : (
          <div className={classes.regions}>
            {regionsList.map((region, i) => {
              return (
                <RegionCard
                  key={i}
                  onDelete={() => {
                    const locationCount = Number(region.locations_count || 0);
                    setDeleteId(
                      locationCount > 0
                        ? `${DELETE_REGION_HAS_LOCATION_PREFIX}${locationCount}_-${region.name}_-${region.id}`
                        : region.id
                    );
                  }}
                  onEdit={() => {
                    navigate(`/regions/edit/${region.id}`);
                  }}
                  data={region}
                  onView={() => {
                    navigate(`/regions/${region.id}`);
                  }}
                />
              );
            })}
          </div>
        )}
        {isEnableRegions && !hasRegions && (
          <Alert
            onClick={() => {
              setIsTurnOff(true);
              updateSettings({
                settings: {
                  enable_regions: false,
                },
              });
            }}
            className={classes.alert}
          />
        )}
      </div>
      {isTurnOff && (
        <TurnOffModal
          isOpen
          onClose={() => {
            setIsTurnOff(false);
            syncUpdateSettings('enable_regions', false);
          }}
        />
      )}
      {!!deleteId && (
        <DeleteModal
          isOpen
          onClose={() => {
            setDeleteId('');
          }}
          title="Delete Region"
          onEdit={(tId) => {
            navigate(`/regions/edit/${tId}`);
          }}
          onSubmit={(cb) => {
            onDelete(
              deleteId,
              () => {
                if (cb) cb();
              },
              () => {
                setDeleteId('');
              }
            );
          }}
          deleteId={deleteId}
        />
      )}
    </>
  );
};

export default Regions;
