import cn from 'classnames';
import FormCheckbox from 'src/components/FormFields/Checkbox/FormCheckbox';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormSwitch from 'src/components/FormFields/Switch/FormSwitch';
import { WEEK_DAYS } from 'src/helper/constants/date';
import classes from './OperatingHours.module.scss';

const OperatingHours = ({ className, control }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>Operating Hours</span>
      </div>
      <div className={classes.content}>
        <ul>
          {WEEK_DAYS.map((day) => {
            const namePrefix = `${day}_`;
            return (
              <li key={day}>
                <div>
                  <FormSwitch name={`${namePrefix}day`} control={control} />
                  <span>{day}</span>
                </div>
                <div>
                  <FormCheckbox
                    name={`${namePrefix}24`}
                    variant="primary"
                    control={control}
                  />
                  <label htmlFor={`${namePrefix}24`}>Open 24 HRS</label>
                </div>
                <div>
                  <span>from</span>
                  <FormInput
                    name={`${namePrefix}from`}
                    placeholder="9:00 AM"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                  <span>to</span>
                  <FormInput
                    name={`${namePrefix}to`}
                    placeholder="5:00 PM"
                    control={control}
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default OperatingHours;
