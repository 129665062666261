import * as settingActions from 'src/store/actions/setting';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    setting: useReducerData('setting', 'setting.data', {}),
    regions: useReducerData('region', 'regions.data', {}),
    regionsLoading: useReducerData('region', 'regions.loading', false),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...settingActions,
  });
};
