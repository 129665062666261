/* eslint-disable max-lines */
import WorkflowNode from './WorkflowNode';

export const initialNodes = [
  {
    id: 'a',
    type: 'Workflow-node',
    position: { x: -141.24858342846287, y: -849.165271145647 },
    data: {
      label: 'Incident investigation',
      subType: 'dnode_form',
      ui_group: 'Evaluation',
      task_data: [
        {
          id: '21CE19F0-230F-4F87-8E19-856B934319C3',
          element: 'TextArea',
          text: 'Multi-line',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_area_70FE62C6-E452-4809-85D6-9964B7B65CF3',
          label: 'Incident description ',
          variableKey: 'Incident_description',
          readOnly: false,
          dirty: false,
        },
        {
          id: '50D4E4FB-0CA2-4867-BE8F-6CB6AC8CFEDA',
          element: 'Dropdown',
          text: 'Dropdown',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'dropdown_BBFE8475-D7EC-419D-8803-BC82EAE41E34',
          label: 'Incident location ',
          options: [
            {
              value: 'location_a',
              text: 'Location A',
              key: 'dropdown_option_AF9BB700-8B17-4BB1-B663-F2C57094E140',
            },
            {
              value: 'location_b',
              text: 'Location B',
              key: 'dropdown_option_3E8311A6-C7A1-448D-B91B-63B8CDD7D2EF',
            },
            {
              value: 'location_c',
              text: 'Location C',
              key: 'dropdown_option_07BED3D7-2508-497A-A359-C61395B42BB8',
            },
          ],
          variableKey: 'Incident_location',
          readOnly: false,
          dirty: false,
        },
        {
          id: 'A1B08994-F1B5-41B6-AB1A-A1F6D320300C',
          element: 'Dropdown',
          text: 'Dropdown',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'dropdown_571AFC40-43C3-4477-A1C2-9205777B75F1',
          label: 'Incident type ',
          options: [
            {
              value: 'near_miss',
              text: 'Near Miss',
              key: 'dropdown_option_C7566FA7-7930-4DC8-AA35-67B51A2BBBA3',
            },
            {
              value: 'vapor_release',
              text: 'Vapor Release',
              key: 'dropdown_option_CE4E4B37-5943-43EB-B6ED-C987D7B78FCA',
            },
            {
              value: 'liquid_spill',
              text: 'Liquid Spill',
              key: 'dropdown_option_68438238-572D-4102-A4EB-4ED0666AE93B',
            },
            {
              value: 'fire_explosion',
              text: 'Fire/Explosion',
              key: 'CDD29507-41B2-4805-822A-D1C5704D93AA',
            },
            {
              value: 'other',
              text: 'Other',
              key: '64B11B99-59DA-4AC2-9F47-AF11FDE98A01',
            },
          ],
          variableKey: 'Incident_type',
          readOnly: false,
          dirty: false,
        },
        {
          id: '8FA80740-CF3F-43B2-AB83-523F9F518DE3',
          element: 'TextInput',
          text: 'Text Input',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_input_47F96D29-0348-4110-89E0-E72294853A36',
          label: 'Observed by ',
          dirty: false,
          variableKey: 'Observed_by',
          readOnly: false,
        },
        {
          id: 'C84D3B7F-6EA7-4EB7-A8E9-3F544FF17FDD',
          element: 'TextInput',
          text: 'Text Input',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_input_00FC49DF-7211-4BE8-AB4A-ACAB9CAB27D6',
          label: 'Notified supervisor ',
          variableKey: 'Notified_supervisor',
          readOnly: false,
          dirty: false,
        },
        {
          id: '31A5908E-B3E2-4241-A666-7D24E2C2D1A5',
          element: 'RadioButtons',
          text: 'Radio choice',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'radiobuttons_F665BF8F-2A4A-4555-A3A8-7A49A2971692',
          label: 'Consultant or Third party is contacted ',
          options: [
            {
              value: 'n',
              text: 'No',
              key: 'radiobuttons_option_E6535FB2-DB19-44C4-B3D9-2A652A080025',
            },
            {
              value: 'y',
              text: 'Yes',
              key: '53A32C1D-E79D-4A9B-9BAD-2A3B44A680DA',
            },
          ],
          variableKey: 'Consultant_or_Third_party_is_contacted',
          readOnly: false,
          dirty: true,
        },
      ],
      description: 'Incident investigation and RCA.',
    },
    measured: { width: 150, height: 94 },
    selected: false,
    dragging: false,
    key: 'Incident_investigation',
    analysis: { passed: true, order: 10, failed: false },
  },
  {
    id: '0.3223508839655045',
    position: { x: -266.20727889180296, y: -660.6185379543533 },
    type: 'Workflow-node',
    data: {
      label: 'Consultant or Third party is contacted',
      subType: 'dnode_cond',
      subSubType: 'field_value',
      js_code: "Consultant_or_Third_party_is_contacted === 'y'",
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 83 },
    key: 'Consultant_or_Third_party_is_contacted',
    analysis: { passed: false, order: 11, failed: true },
    selected: false,
    dragging: false,
  },
  {
    id: '0.37154557711294767',
    position: { x: -263.9237761280207, y: -547.9082403541836 },
    type: 'Workflow-node',
    data: {
      label: 'Consultant or Third party info',
      subType: 'dnode_form',
      task_data: [
        {
          id: '2DB82858-2398-4680-B5D7-E173E4FCB8D8',
          element: 'TextInput',
          text: 'Text Input',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_input_51DE230B-699A-45E4-9ECE-D398E7ED2E76',
          label: 'Consultant or Third party name ',
          variableKey: 'Consultant_or_Third_party_name',
          readOnly: false,
          dirty: false,
        },
        {
          id: '5AA5CD2B-5F20-4F1C-9F9D-936700B6126C',
          element: 'TextInput',
          text: 'Text Input',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_input_0960CB53-DEE4-4B9A-9E82-CFEB65D5F45E',
          label: 'Consultant or Third party contact ',
          variableKey: 'Consultant_or_Third_party_contact',
          readOnly: false,
          dirty: false,
        },
        {
          id: 'E9CB5B53-FEF8-4110-A67B-478056FFE2B9',
          element: 'TextArea',
          text: 'Multi-line',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_area_1EF6FB57-A5E0-40FB-B4B8-77E020035A44',
          label: 'Consultant or Third party notes ',
          variableKey: 'Consultant_or_Third_party_notes',
          readOnly: false,
          dirty: false,
        },
      ],
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 83 },
    key: 'Consultant_or_Third_party_info',
    analysis: { passed: false },
    selected: false,
    dragging: true,
  },
  {
    id: '0.20717839638869662',
    position: { x: 363.8157086716943, y: -517.9504106445642 },
    type: 'Workflow-node',
    data: {
      label: 'Incident type: Other',
      subType: 'dnode_cond',
      subSubType: 'field_value',
      js_code: "Incident_type === 'other'",
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    selected: false,
    dragging: false,
    key: 'Incident_type:_Other',
    analysis: { passed: false, order: 11, failed: true },
  },
  {
    id: '0.8129624492906533',
    position: { x: 365.1681454675367, y: -425.20853544444543 },
    type: 'Workflow-node',
    data: {
      label: 'Other Incident type info',
      subType: 'dnode_form',
      task_data: [
        {
          id: 'A1923CFD-C1F7-4D75-8473-E0BB21352936',
          element: 'TextInput',
          text: 'Text Input',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_input_7803CA19-0784-4A4B-BD29-30CF1885ECD2',
          label: 'Other incident type title ',
          variableKey: 'Other_incident_type_title',
          readOnly: false,
          dirty: false,
        },
        {
          id: '3EDD1C72-1E99-4980-8C0A-36FD7BEEC538',
          element: 'TextArea',
          text: 'Multi-line',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_area_51A337C0-977C-4ACE-A2AD-F665B64FF087',
          label: 'Other incident type descrition ',
          variableKey: 'Other_incident_type_descrition',
          readOnly: false,
          dirty: false,
        },
      ],
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 83 },
    selected: false,
    dragging: false,
    key: 'Other_Incident_type_info',
    analysis: { passed: false },
  },
  {
    id: '0.4261102818047706',
    position: { x: -30, y: -525 },
    type: 'Workflow-node',
    data: {
      label: 'Fire/Explosion',
      subType: 'dnode_cond',
      subSubType: 'field_value',
      js_code: "Incident_type === 'fire_explosion'",
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    key: 'Fire/Explosion',
    analysis: { passed: false, order: 11, failed: true },
    selected: false,
  },
  {
    id: '0.8355292323542867',
    position: { x: -30, y: -420 },
    type: 'Workflow-node',
    data: {
      label: 'Fire/Explosion Incident type info',
      subType: 'dnode_form',
      ui_group: 'Evaluation',
      task_data: [
        {
          id: 'E7B318CA-6DD3-487F-8285-518CD495343F',
          element: 'TextInput',
          text: 'Text Input',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_input_C82E6171-FB22-44D3-8064-1546E7547691',
          label: 'Effected radious ',
          variableKey: 'Effected_radious',
          readOnly: false,
          dirty: false,
        },
        {
          id: 'A8644EA8-2FD3-498F-A62D-75F98EF9E48B',
          element: 'TextInput',
          text: 'Text Input',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_input_D0B3C3CE-6AD9-4561-BBD8-DB58C4CE0D11',
          label: 'Wind direction ',
          variableKey: 'Wind_direction',
          readOnly: false,
          dirty: false,
        },
      ],
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 83 },
    selected: false,
    key: 'Fire/Explosion_Incident_type_info',
    analysis: { passed: false },
  },
  {
    id: '0.2275574927660553',
    position: { x: 156.60660088844944, y: -525 },
    type: 'Workflow-node',
    data: {
      label: 'Liquid Spill',
      subType: 'dnode_cond',
      subSubType: 'field_value',
      ui_group: 'Evaluation',
      description: '',
      js_code: "Incident_type === 'liquid_spill'",
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    selected: false,
    dragging: false,
    key: 'Liquid_Spill',
    analysis: { passed: false, order: 11, failed: true },
  },
  {
    id: '0.7515202632122984',
    position: { x: 156.60660088844944, y: -420.9707890747555 },
    type: 'Workflow-node',
    data: {
      label: 'Liquid Spill Incident type info',
      subType: 'dnode_form',
      task_data: [
        {
          id: '08843181-6A0C-496A-AF26-301D2A8D3B0D',
          element: 'NumberInput',
          text: 'Number Input',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'number_input_157038B0-05F9-4682-A5F7-32036BC6BBE5',
          label: 'Amount of lb released ',
          variableKey: 'Amount_of_lb_released',
          readOnly: false,
          dirty: false,
        },
        {
          id: '5B64C2B8-2A3D-4E4A-BBD2-09A034491B1E',
          element: 'Dropdown',
          text: 'Dropdown',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'dropdown_C8473B04-AADF-47E9-ACB4-CDC479F62311',
          label: 'Liquid type ',
          options: [
            {
              value: 'gas',
              text: 'Gas',
              key: 'dropdown_option_A24DBAB2-EF2C-4B67-81AA-222B396D04B7',
            },
            {
              value: 'aminia',
              text: 'Aminia',
              key: 'dropdown_option_3F893149-BB4A-4909-87F2-107179643427',
            },
          ],
          variableKey: 'Liquid_type',
          readOnly: false,
          dirty: false,
        },
      ],
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 83 },
    selected: false,
    dragging: false,
    key: 'Liquid_Spill_Incident_type_info',
    analysis: { passed: false },
  },
  {
    id: '0.24146711933971177',
    position: { x: 106.48954809397114, y: -211.0980770029213 },
    type: 'Workflow-node',
    data: {
      label: 'Offsite impact',
      subType: 'dnode_form',
      task_data: [
        {
          id: '98DDCC88-65F0-4452-9BA5-C5156D804006',
          element: 'RadioButtons',
          text: 'Radio choice',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'radiobuttons_6FF556E6-4CA6-43DD-9B84-1B7E32E24BFA',
          label: 'Were there offsite impacts ',
          options: [
            {
              value: 'y',
              text: 'Yes',
              key: 'radiobuttons_option_09E2B5DE-F75F-47AE-966B-4014ADBC31B2',
            },
            {
              value: 'n',
              text: 'No',
              key: 'radiobuttons_option_B1417EF9-B23E-4641-8168-EEFCAE1B5A08',
            },
          ],
          variableKey: 'Were_there_offsite_impacts',
          readOnly: false,
          dirty: false,
        },
        {
          id: '1DD26B1D-6674-4419-9D20-FED4939DB1D0',
          element: 'RadioButtons',
          text: 'Radio choice',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'radiobuttons_B4B82C77-0C04-4B97-981E-6540DF34E3C6',
          label: 'Were there casualties ',
          options: [
            {
              value: 'y',
              text: 'Yes',
              key: 'radiobuttons_option_B422F153-A21F-4B08-A6F2-2A770C7E6F3E',
            },
            {
              value: 'n',
              text: 'No',
              key: 'radiobuttons_option_57B5A4A6-63DB-427A-933E-43700F9DFA94',
            },
          ],
          variableKey: 'Were_there_casualties',
          readOnly: false,
          dirty: false,
        },
      ],
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    selected: false,
    dragging: false,
    key: 'Offsite_impact',
    analysis: { passed: false },
  },
  {
    id: '0.0025609993713409107',
    position: { x: 734.4910251900981, y: -517.3091608759416 },
    type: 'Workflow-node',
    data: {
      label: 'Near Miss',
      subType: 'dnode_cond',
      subSubType: 'field_value',
      ui_group: 'Evaluation',
      js_code: "Incident_type === 'near_miss'",
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    selected: false,
    dragging: false,
    key: 'Near_Miss',
    analysis: { passed: false, order: 11, failed: true },
  },
  {
    id: '0.7364752503511536',
    position: { x: 735.7018582671452, y: -423.9602149293429 },
    type: 'Workflow-node',
    data: {
      label: 'Near Miss incident type info',
      subType: 'dnode_form',
      ui_group: 'Data entry',
      task_data: [
        {
          id: 'E6318D6E-D0B6-4BA4-8C83-04BF7E4AFAC5',
          element: 'TextInput',
          text: 'Text Input',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_input_39880CD7-DB49-4A56-891C-260275489533',
          label: 'Near Miss title ',
          variableKey: 'Near_Miss_title',
          readOnly: false,
          dirty: false,
        },
        {
          id: 'B39CDE3E-1D3D-4A10-9C1E-023DDADC34B2',
          element: 'TextArea',
          text: 'Multi-line',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_area_FEDA4F90-8990-47C2-A1C7-5F9F8751BF4B',
          label: 'Near Miss description ',
          variableKey: 'Near_Miss_description',
          readOnly: false,
          dirty: true,
        },
      ],
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 83 },
    selected: false,
    dragging: false,
    key: 'Near_Miss_incident_type_info',
    analysis: { passed: false },
  },
  {
    id: '0.12487094503763907',
    position: { x: 555, y: -510 },
    type: 'Workflow-node',
    data: {
      label: 'Vapor Release',
      subType: 'dnode_cond',
      subSubType: 'field_value',
      ui_group: 'Evaluation',
      js_code: "Incident_type === 'vapor_release'",
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    selected: true,
    key: 'Vapor_Release',
    analysis: { passed: false, order: 11, failed: true },
  },
  {
    id: '0.401245858839685',
    position: { x: 555, y: -420 },
    type: 'Workflow-node',
    data: {
      label: 'Vapor Release Incident type',
      subType: 'dnode_form',
      task_data: [
        {
          id: 'E2D14EEC-F341-4E2D-92D2-3A22727987C2',
          element: 'Dropdown',
          text: 'Dropdown',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'dropdown_470B0762-A55C-471C-8922-6B78FBFD6DF2',
          label: 'Vapor type ',
          options: [
            {
              value: 'oxigen',
              text: 'Oxigen',
              key: 'dropdown_option_0DA11865-75E2-40CF-9C50-52FBD767196F',
            },
            {
              value: 'hydrogen',
              text: 'Hydrogen',
              key: 'dropdown_option_3171A9C0-E843-4DDE-945C-EE5A6E7BE911',
            },
          ],
          variableKey: 'Vapor_type',
          readOnly: false,
          dirty: false,
        },
        {
          id: 'CAF8B95E-C7C7-4676-A5B0-57E35797B23C',
          element: 'RadioButtons',
          text: 'Radio choice',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'radiobuttons_D0322AED-96CF-44C2-B845-0AE1C07894E3',
          label: 'What was the Primary Source of Release ',
          options: [
            {
              value: 'part_1',
              text: 'part 1',
              key: 'radiobuttons_option_C28B5025-F044-4CE9-920B-AEC29845F479',
            },
            {
              value: 'par_2',
              text: 'par 2',
              key: 'radiobuttons_option_150F35AC-09EA-4ABB-AF48-8BC95A1FD567',
            },
            {
              value: 'part_3',
              text: 'part 3',
              key: 'radiobuttons_option_D6CAEA6B-D977-4DD4-9767-1BDC5D35B445',
            },
          ],
          variableKey: 'What_was_the_Primary_Source_of_Release',
          readOnly: false,
          dirty: false,
        },
      ],
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 69 },
    key: 'Vapor_Release_Incident_type',
    analysis: { passed: false },
  },
  {
    id: '0.5274840045236684',
    position: { x: 106.14467503945323, y: -108.48143318757674 },
    type: 'Workflow-node',
    data: {
      label: 'Were there offsite impacts',
      subType: 'dnode_cond',
      subSubType: 'field_value',
      js_code: "Were_there_casualties == 'y'",
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 83 },
    selected: false,
    dragging: false,
    key: 'Were_there_offsite_impacts',
    analysis: { passed: false },
  },
  {
    id: '0.9345908392068762',
    position: { x: 105.31370608122903, y: 11.330343163685853 },
    type: 'Workflow-node',
    data: {
      label: 'Casualties',
      subType: 'dnode_form',
      task_data: [
        {
          id: 'AC7E86CE-188F-4853-9526-0AF01B5ADF12',
          element: 'NumberInput',
          text: 'Number Input',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'number_input_07CAD31A-F47D-46F0-85D1-F8624EA00BA7',
          label: 'Number of injured ',
          variableKey: 'Number_of_injured',
          readOnly: false,
          dirty: false,
        },
        {
          id: '2588268C-9810-40A5-91A4-9C230FABDC0E',
          element: 'NumberInput',
          text: 'Number Input',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'number_input_C28D3FE1-F5E1-4C50-A3F5-6A35524B1898',
          label: 'Number of deaths ',
          variableKey: 'Number_of_deaths',
          readOnly: false,
          dirty: true,
        },
        {
          id: '0F8AAFC7-EE6A-4DD1-A158-01829FF9A753',
          element: 'TextArea',
          text: 'Multi-line',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_area_0A5953EF-49E4-4A6F-B497-CBD8E0BD35BA',
          label: 'Affected employee names and numbers ',
          variableKey: 'Affected_employee_names_and_numbers',
          readOnly: false,
          dirty: true,
        },
      ],
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    selected: false,
    dragging: false,
    key: 'Casualties',
    analysis: { passed: false },
  },
  {
    id: '0.38396505954081395',
    position: { x: -144.95513520872873, y: 92.6832826235966 },
    type: 'Workflow-node',
    data: {
      label: 'Incident info',
      subType: 'dnode_form',
      task_data: [
        {
          id: 'B24A37E8-5F84-4851-9480-563D082DE157',
          element: 'NumberInput',
          text: 'Number Input',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'number_input_FB7557D3-CDB3-4414-8E65-5BFA33161B67',
          label: 'What was the wind speed ',
          variableKey: 'What_was_the_wind_speed',
          readOnly: false,
          dirty: false,
        },
        {
          id: '0B07C081-A369-4150-801F-BEE225CF68B9',
          element: 'Dropdown',
          text: 'Dropdown',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'dropdown_6A53CB32-DDCC-450E-BF5A-DC2F7C7F2B36',
          label: 'Wind direction ',
          options: [
            {
              value: 'n',
              text: 'N',
              key: 'dropdown_option_FE0121B3-D2E7-4CF0-BE53-A72C3876E60D',
            },
            {
              value: 'nw',
              text: 'NW',
              key: 'dropdown_option_071F641F-C5C9-472F-941F-E304224B1137',
            },
            {
              value: 'w',
              text: 'W',
              key: 'dropdown_option_279281D3-860D-4378-8528-B1F2B1FED924',
            },
            {
              value: 'sw',
              text: 'SW',
              key: '7DC700EA-413C-4D9E-A0D3-21928186D418',
            },
            {
              value: 's',
              text: 'S',
              key: '76C308A5-8B3A-4C6D-A400-043AF53D0244',
            },
            {
              value: 'se',
              text: 'SE',
              key: '8DD2D9EE-64CF-42E5-A180-77C467E450A1',
            },
            {
              value: 'e',
              text: 'E',
              key: 'A961A6C1-245D-4C33-97AE-8DAAD048A67D',
            },
            {
              value: 'ne',
              text: 'NE',
              key: '79B6A981-BA8A-463C-8333-8C9F2C37B049',
            },
          ],
          variableKey: 'Wind_direction',
          readOnly: false,
          dirty: true,
        },
        {
          id: '4AA3D0ED-EABE-4DCD-85AA-E61728770965',
          element: 'Dropdown',
          text: 'Dropdown',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'dropdown_BE48D614-1123-4A8F-8A59-E41218810B3E',
          label: 'Atmospheric Stability class ',
          options: [
            {
              value: 'class_1',
              text: 'class 1',
              key: 'dropdown_option_7C5E55ED-116F-4D58-AC49-B2F580B91D75',
            },
            {
              value: 'class_2',
              text: 'class 2',
              key: 'dropdown_option_3978B9DB-9694-4450-AC42-5936218ACEF9',
            },
            {
              value: 'class_2',
              text: 'class 2',
              key: 'dropdown_option_DDFF3860-901E-4359-B1A0-1B6A221B310A',
            },
          ],
          variableKey: 'Atmospheric_Stability_class',
          readOnly: false,
          dirty: false,
        },
        {
          id: 'CC227A0B-891E-4B14-B699-E87C0C48DBB1',
          element: 'FileUpload',
          text: 'File Upload',
          required: false,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'file_upload_F666F8CD-A06E-4E48-B24A-D9C2CDCA1E5E',
          label: 'Incident picuters ',
          variableKey: 'Incident_picuters',
          readOnly: false,
          dirty: false,
        },
        {
          id: '87CA3FB6-3AB3-4DC8-8B73-0A9AA5B9CE9F',
          element: 'FileUpload',
          text: 'File Upload',
          required: false,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'file_upload_AFDFF738-5D7D-43B6-8064-D6C60729E238',
          label: 'Incident files ',
          variableKey: 'Incident_files',
          readOnly: false,
          dirty: true,
        },
      ],
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    selected: false,
    dragging: false,
    key: 'Incident_info',
    analysis: { passed: true, order: 20, failed: false },
  },
  {
    id: '0.9894621993453097',
    position: { x: -145.6821904618169, y: 191.64930946272187 },
    type: 'Workflow-node',
    data: {
      label: 'Root Cause Analysis',
      subType: 'dnode_form',
      task_data: [
        {
          id: '6B324DE5-28A8-4333-8FCD-8EC41AF6BA3E',
          element: 'TextArea',
          text: 'Multi-line',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_area_00724E53-F859-4815-AD31-71576A599250',
          label: 'Root Cause Analysis description ',
          variableKey: 'Root_Cause_Analysis_description',
          readOnly: false,
          dirty: false,
        },
        {
          id: '2C51B7F9-C8F7-4342-B8DE-2294398A9B76',
          element: 'FileUpload',
          text: 'File Upload',
          required: false,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'file_upload_270AA3D4-EFFD-4F48-B3F4-F93908A6FEC5',
          label: 'Root Cause Analysis uploads ',
          variableKey: 'Root_Cause_Analysis_uploads',
          readOnly: false,
          dirty: false,
        },
      ],
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    selected: false,
    dragging: false,
    key: 'Root_Cause_Analysis',
    analysis: { passed: true, order: 30, failed: false },
  },
  {
    id: '0.029002907603363104',
    position: { x: -146.74806236764846, y: 270 },
    type: 'Workflow-node',
    data: {
      label: 'Changes Recommended',
      subType: 'dnode_form',
      task_data: [
        {
          id: '403C3786-150D-48E9-8C43-475B9916F1DA',
          element: 'RadioButtons',
          text: 'Radio choice',
          required: false,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'radiobuttons_3DB91D4A-6369-4194-AE2B-6FC023623EB8',
          label: 'Are there recommended changes ',
          options: [
            {
              value: 'y',
              text: 'Yes',
              key: 'radiobuttons_option_50B2B47D-3A31-472F-968E-FB27EB0378A5',
            },
            {
              value: 'n',
              text: 'No',
              key: 'radiobuttons_option_AB6A96F3-1F83-4B3F-A7B5-60D2FC89E567',
            },
          ],
          variableKey: 'Are_there_recommended_changes',
          readOnly: false,
          dirty: true,
        },
      ],
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 83 },
    selected: false,
    dragging: false,
    key: 'Changes_Recommended',
    analysis: { passed: true, order: 40, failed: false },
  },
  {
    id: '0.30985942933521526',
    position: { x: -240, y: 405 },
    type: 'Workflow-node',
    data: {
      label: 'There are recommended changes',
      subType: 'dnode_cond',
      subSubType: 'field_value',
      js_code: "Are_there_recommended_changes === 'y'",
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 87 },
    selected: false,
    dragging: false,
    key: 'There_are_recommended_changes',
    analysis: { passed: false, order: 41, failed: true },
  },
  {
    id: '0.9288051616630053',
    position: { x: -240, y: 555 },
    type: 'Workflow-node',
    data: {
      label: 'Recommended changes',
      subType: 'dnode_form',
      task_data: [
        {
          id: '0550495D-DD6E-4F40-BD81-601F1391DEBC',
          element: 'TextArea',
          text: 'Multi-line',
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'text_area_FCC09D40-5217-4061-95C9-3EC9AFAAE66B',
          label: 'Recommended changes details ',
          variableKey: 'Recommended_changes_details',
          readOnly: false,
          dirty: true,
        },
      ],
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 69 },
    key: 'Recommended_changes',
    analysis: { passed: false },
  },
  {
    id: '0.8675627438813485',
    position: { x: -83.5602072320948, y: 735.7476186139486 },
    type: 'Workflow-node',
    data: {
      label: 'Incident Review',
      subType: 'dnode_form',
      task_data: [
        {
          id: '23C6B6DF-B257-4EE3-A59F-E74F68A82EE2',
          element: 'Signature',
          text: 'Signature',
          group_name: 'Advanced',
          required: true,
          readOnly: false,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          field_name: 'signature_328CF076-2C22-47A2-BA4A-2E176000B90D',
          label:
            'Sign to confirm this incident report is correct and complete ',
          variableKey:
            'Sign_to_confirm_this_incident_report_is_correct_and_complete',
          dirty: false,
        },
      ],
      ui_group: 'Evaluation',
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 65 },
    selected: false,
    dragging: false,
    key: 'Incident_Review',
    analysis: { passed: true, order: 51, failed: false },
  },
  {
    id: '0.9165217305094666',
    position: { x: 30, y: 495 },
    type: 'Workflow-node',
    data: {
      label: 'There are no recommended changes',
      subType: 'dnode_cond',
      subSubType: 'field_value',
      js_code: "Are_there_recommended_changes === 'n'",
    },
    origin: [0.5, 0],
    measured: { width: 150, height: 87 },
    key: 'There_are_no_recommended_changes',
    analysis: { passed: true, order: 41, failed: false },
  },
];

export const nodeTypes = {
  'Workflow-node': WorkflowNode,
  // Add any of your custom nodes here!
};
