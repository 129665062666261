import {
  apiCreateLocation,
  apiDeleteLocation,
  apiGetLocationDetails,
  apiGetLocations,
} from 'src/api';
import Types from '../types/location';

export const syncAddAllLocations = (newLocation) => (dispatch) => {
  dispatch({
    type: Types.SYNC_ADD_ALL_LOCATIONS,
    payload: newLocation,
  });
};
export const getAllLocations = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_ALL_LOCATIONS,
    asyncCall: () => {
      return apiGetLocations({
        page: 1,
        per_page: 1000,
      });
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getLocations = (params, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_LOCATIONS,
    asyncCall: () => {
      return apiGetLocations(params);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const getLocationDetails = (id, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.GET_LOCATION_DETAILS,
    asyncCall: () => {
      return apiGetLocationDetails(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const createLocation = (data, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_LOCATION,
    asyncCall: () => {
      return apiCreateLocation(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const updateLocation = (data, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_LOCATION,
    asyncCall: () => {
      return apiCreateLocation(data);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
export const deleteLocation = (id, cb) => {
  return {
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_LOCATION,
    asyncCall: () => {
      return apiDeleteLocation(id);
    },
    afterSuccessCall: (_dispatch, response) => {
      if (cb) cb(response);
    },
    afterFailureCall: (_dispatch, err) => {
      if (cb) cb(false, err);
    },
  };
};
