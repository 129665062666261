import { useController } from 'react-hook-form';
import InputPassword from './';

const FormInputPassword = ({ name, control, onChange, ...props }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <InputPassword
      {...props}
      {...field}
      error={error?.message}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
          field.onChange(e);
        } else {
          field.onChange(e);
        }
      }}
    />
  );
};

export default FormInputPassword;
