import { Handle, Position } from '@xyflow/react';

export default function WorkflowNode(props) {
  const { positionAbsoluteX, positionAbsoluteY, data } = props;
  const x = `${Math.round(positionAbsoluteX)}px`;
  const y = `${Math.round(positionAbsoluteY)}px`;

  return (
    // We add this class to use the same styles as React Flow's default nodes.
    <div
      className={
        (data.subType || 'dnode_unknown') + ' react-flow__node-default dnode'
      }
    >
      {/* {JSON.stringify(data)} */}
      <div>
        <i className="is-isolated fas fa-square"></i>
        <small className="dnode_type">
          {data.subType == 'dnode_form'
            ? 'Form'
            : data.subType == 'dnode_cond'
            ? 'Condition'
            : data.subType == 'dnode_action'
            ? 'Action'
            : '.'}
        </small>
        {/* {data.title && ' - ' + data.title} */}
        {data.label && (
          <div>
            {data.label}
            {data.ui_group && <small>{data.ui_group}</small>}
            {data.subType && <hr />}
            <small>{data.description}</small>
          </div>
        )}
      </div>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
}
