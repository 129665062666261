export const getUpdatedSettings = (data = [], key, value) => {
  return data.map((obj) => {
    if (obj.key === key) {
      return {
        key,
        value,
      };
    }
    return obj;
  });
};
