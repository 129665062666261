import cn from 'classnames';
import { InputGroupAddon, InputGroupText } from 'reactstrap';
import classes from './InputSuffix.module.scss';

const InputSuffix = ({ className, children }) => {
  return (
    <InputGroupAddon
      addonType="prepend"
      className={cn(classes.wrapper, className)}
    >
      <InputGroupText>{children}</InputGroupText>
    </InputGroupAddon>
  );
};

export default InputSuffix;
