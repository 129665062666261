import cn from 'classnames';
import { Doughnut } from 'react-chartjs-2';
import { ReactComponent as LocationSvg } from 'src/assets/icons/location.svg';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import Score from 'src/components/Score';
import Map from '../Map';
import ScoreChart from '../ScoreChart';
import ScoreReport from '../ScoreReport';
import classes from './LocationCard.module.scss';

const chartData = {
  labels: ['Gas Leaks', 'Human Injury', 'Vapor Release'],
  datasets: [
    {
      data: [2, 2, 1],
      backgroundColor: ['#082A15', '#99A096', '#52565B'],
      borderColor: ['#082A15', '#99A096', '#52565B'],
    },
  ],
};
const plugins = [
  {
    beforeDraw: function (chart) {
      const width = chart.width;
      const height = chart.height;
      const ctx = chart.ctx;
      ctx.restore();
      ctx.font = '800 5em Inter';
      ctx.textBaseline = 'top';
      const text = '5';
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 - 60;
      ctx.fillText(text, textX, textY);
      ctx.font = '400 1.5em Inter';
      ctx.textBaseline = 'top';
      const text1 = 'Open incidents';
      const text1X = Math.round((width - ctx.measureText(text1).width) / 2);
      const text1Y = height / 2 + 20;
      ctx.fillText(text1, text1X, text1Y);

      ctx.save();
    },
  },
];

const LocationCard = ({
  className,
  onEdit,
  onDelete,
  data,
  onView,
  hideView,
  hideActions,
  dropdowns,
}) => {
  const actionDropdown = dropdowns || [
    {
      key: 'edit',
      label: 'Edit Location',
      onClick: onEdit,
    },
    {
      key: 'delete',
      label: 'Archive Location',
      onClick: onDelete,
    },
  ];
  const locationCount = Number(data?.locations_count || 0);
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.header}>
        <span>Americold Logistics Nampa</span>
        {!hideActions && (
          <Dropdown items={actionDropdown}>
            <MoreSvg />
          </Dropdown>
        )}
      </div>
      <Score
        score={90}
        width={226}
        activeColor="#45B942"
        issuesColor="#C1FFC0"
        color="#EEEEEE"
        title=" "
        className={classes.score}
        issues={5}
      />
      <ScoreChart
        title=""
        scores={[
          100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 100, 100, 100, 100,
          100, 100, 100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 60, 60,
        ]}
      />
      <div className={classes.footer}>
        <span>
          <span>231 4th Ave N</span>
          <span> Nampa, ID 83687</span>
        </span>
        {hideView ? <span></span> : <span onClick={onView}>View Location</span>}
      </div>
    </div>
  );
};

export default LocationCard;
