import cn from 'classnames';
import { Doughnut } from 'react-chartjs-2';
import { ReactComponent as LocationSvg } from 'src/assets/icons/location.svg';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import Dropdown from 'src/components/Dropdown';
import Score from 'src/components/Score';
import Map from '../Map';
import ScoreChart from '../ScoreChart';
import ScoreReport from '../ScoreReport';
import classes from './Card.module.scss';

const chartData = {
  labels: ['Gas Leaks', 'Human Injury', 'Vapor Release'],
  datasets: [
    {
      data: [2, 2, 1],
      backgroundColor: ['#082A15', '#99A096', '#52565B'],
      borderColor: ['#082A15', '#99A096', '#52565B'],
    },
  ],
};
const plugins = [
  {
    beforeDraw: function (chart) {
      const width = chart.width;
      const height = chart.height;
      const ctx = chart.ctx;
      ctx.restore();
      ctx.font = '800 5em Inter';
      ctx.textBaseline = 'top';
      const text = '5';
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 - 60;
      ctx.fillText(text, textX, textY);
      ctx.font = '400 1.5em Inter';
      ctx.textBaseline = 'top';
      const text1 = 'Open incidents';
      const text1X = Math.round((width - ctx.measureText(text1).width) / 2);
      const text1Y = height / 2 + 20;
      ctx.fillText(text1, text1X, text1Y);

      ctx.save();
    },
  },
];

const RegionCard = ({
  className,
  onEdit,
  onDelete,
  data,
  onView,
  hideView,
  hideActions,
  title,
  dropdowns,
  subHeading,
  type,
  isLocation,
}) => {
  const isMap = type === 'map';
  const isScore = type === 'score';
  const isIssue = type === 'issue';
  const actionDropdown = dropdowns || [
    {
      key: 'edit',
      label: 'Edit Region',
      onClick: onEdit,
    },
    {
      key: 'delete',
      label: 'Delete Region',
      onClick: onDelete,
    },
  ];
  const locationCount = Number(data?.locations_count || 0);
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isIssue]: isScore,
          [classes.isLocation]: isLocation,
        },
        className
      )}
    >
      <div className={classes.header}>
        <span>{title || data?.name}</span>
        {!hideActions && (
          <Dropdown items={actionDropdown}>
            <MoreSvg />
          </Dropdown>
        )}
      </div>
      {!!subHeading && (
        <div className={classes.subHeading}>
          <span>{subHeading}</span>
        </div>
      )}
      {isMap ? (
        <Map />
      ) : isScore ? (
        <div className={classes.scoreContent}>
          <Score
            score={67}
            width={226}
            activeColor="#EFA34E"
            issuesColor="#FFDD9B"
            color="#EEEEEE"
            title=" "
            className={classes.score}
            issues={5}
          />
          {!isLocation ? (
            <>
              <ScoreChart
                title="Last 30 Days"
                scores={[
                  100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 100, 100,
                  100, 100, 100, 100, 100, 100, 100, 60, 60, 60, 30, 30, 100,
                  100, 100, 60, 60,
                ]}
              />
              <ScoreReport
                title="Report Card"
                description="View a detailed breakdown of your region"
                className={classes.scoreReport}
              />
            </>
          ) : (
            <div className={classes.locationScoreRight}>
              <ScoreChart
                scores={[
                  100, 100, 100, 60, 60, 60, 30, 30, 100, 100, 100, 100, 100,
                  100, 100, 100, 100, 100, 100, 100, 60, 60, 60, 30, 30, 100,
                  100, 100, 60, 60,
                ]}
              />
              <div>
                <div>No Issues in the last 4 days</div>
              </div>
            </div>
          )}
        </div>
      ) : isIssue ? (
        <div className={classes.issueContent}>
          <Doughnut
            data={chartData}
            width={100}
            height={100}
            options={{
              cutoutPercentage: 85,
              legend: false,
            }}
            plugins={plugins}
          />
          {/* <div className={classes.issueInfo}>
            <div>
              <span>5</span>
              <span>Open incidents</span>
            </div>
          </div> */}
          <div className={classes.issueLegends}>
            <span>
              <span style={{ background: '#082A15' }}></span>
              <span>Gas Leaks</span>
              <span>2</span>
            </span>
            <span>
              <span style={{ background: '#AB8A44' }}></span>
              <span>Explosions</span>
              <span>0</span>
            </span>
            <span>
              <span style={{ background: '#52565B' }}></span>
              <span>Vapor Release</span>
              <span>1</span>
            </span>
            <span>
              <span style={{ background: '#99A096' }}></span>
              <span>Human Injury</span>
              <span>2</span>
            </span>
            <span>
              <span style={{ background: '#D8D8C6' }}></span>
              <span>Other</span>
              <span>0</span>
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.content}>
            <div>
              <span>Omni Score Goes Here</span>
              <span>0 Issues</span>
            </div>
          </div>
          <div className={classes.description}>{data?.description}</div>
          <div className={classes.footer}>
            <span>
              <LocationSvg />
              <span>
                {locationCount} Location{locationCount === 1 ? '' : 's'}
              </span>
            </span>
            {hideView ? (
              <span></span>
            ) : (
              <span onClick={onView}>View Region</span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RegionCard;
