import { useController } from 'react-hook-form';
import { SELECT_CREATE_OPTION } from 'src/helper/constants';
import Select from './';

const FormSelect = ({ name, control, onChange, hasCreateOption, ...props }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <Select
      {...props}
      {...field}
      error={error?.message}
      onChange={(val) => {
        const tVal = hasCreateOption
          ? val.filter((v) => v.value !== SELECT_CREATE_OPTION)
          : val;
        const e = {
          target: {
            value: tVal,
          },
        };
        if (onChange) {
          onChange(val);
          field.onChange(e);
        } else {
          field.onChange(e);
        }
      }}
    />
  );
};

export default FormSelect;
