import cn from 'classnames';
import { ReactComponent as GlobalEditSvg } from 'src/assets/icons/global-edit.svg';
import { ReactComponent as LocationSvg } from 'src/assets/icons/location.svg';
import Button from 'src/components/Button';
import classes from './EmptyState.module.scss';

const EmptyState = ({ entity, onCreate, className }) => {
  const isLocation = entity === 'location';
  return (
    <div className={cn(classes.wrapper, className)}>
      <div>
        {isLocation ? <LocationSvg /> : <GlobalEditSvg />}
        <span>
          {isLocation
            ? 'No locations created yet. Click below to get started.'
            : 'No regions created yet. Click below to get started.'}
        </span>
        <Button
          variant="primary"
          type="button"
          fontSize="sm"
          onClick={onCreate}
        >
          Create New {isLocation ? 'Location' : 'Region'}
        </Button>
      </div>
    </div>
  );
};

export default EmptyState;
