import * as regionActions from 'src/store/actions/region';
import * as settingActions from 'src/store/actions/setting';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    settings: useReducerData('setting', 'setting.data.data', []),
    regions: useReducerData('region', 'regions.data', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...regionActions,
    ...settingActions,
  });
};
