import cn from 'classnames';
import classes from './Auth.module.scss';

const AuthLayout = ({ children }) => {
  return (
    <div className={cn('main-content', classes.wrapper)}>
      <div className={classes.content}>{children}</div>
      <div className={classes.rightBg} />
    </div>
  );
};

export default AuthLayout;
