import cn from 'classnames';
import { Table as RsTable } from 'reactstrap';
import { ReactComponent as MoreSvg } from 'src/assets/icons/more.svg';
import { ReactComponent as SortSvg } from 'src/assets/icons/sort.svg';
import Checkbox from 'src/components/FormFields/Checkbox';
import classes from './Table.module.scss';

const Table = ({ className, columns = [], data = [], checkable }) => {
  const tCheckedRows = checkable?.checkedRows || [];
  const handleCheckedChange = checkable.onChange
    ? checkable.onChange
    : () => {};
  return (
    <div className={cn(classes.wrapper, className)}>
      <RsTable className="align-items-center table-flush" responsive>
        <colgroup>
          {columns.map(({ width }, index) => {
            return (
              <col
                style={{ width: width ? `${width}px` : undefined }}
                key={index}
              />
            );
          })}
        </colgroup>
        <thead className="thead-light">
          <tr>
            {columns.map(({ label, key, sortable }, index) => {
              if (key === 'action') {
                return <th scope="col" key={key} />;
              }
              return (
                <th scope="col" key={key}>
                  <div className={classes.columnHeaderInner}>
                    {index === 0 && checkable && (
                      <Checkbox
                        variant="primary"
                        id="table-check-all"
                        className={classes.checkbox}
                        checked={
                          tCheckedRows.length === data?.length &&
                          data?.length > 0
                        }
                        onChange={(e) => {
                          handleCheckedChange(e.target.checked ? data : []);
                        }}
                      />
                    )}
                    <span>{label}</span>
                    {sortable && <SortSvg />}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="list">
          {data.map((d) => {
            return (
              <tr key={d.id}>
                {columns.map((column, index) => {
                  const tVal = d[column.key] || '';
                  const tKey = `${column.key}_${d.id}`;
                  if (column.key === 'action') {
                    return (
                      <td key={tKey}>
                        {column.render ? column.render('', d) : <MoreSvg />}
                      </td>
                    );
                  }
                  return (
                    <td key={tKey}>
                      <div className={classes.tdInner}>
                        {index === 0 && checkable && (
                          <Checkbox
                            variant="primary"
                            id={`table-check-${d.id}`}
                            className={classes.checkbox}
                            checked={
                              !!tCheckedRows.find((obj) => obj.id === d.id)
                            }
                            onChange={(e) => {
                              handleCheckedChange(
                                e.target.checked
                                  ? [...tCheckedRows, d]
                                  : tCheckedRows.filter(
                                      (obj) => obj.id !== d.id
                                    )
                              );
                            }}
                          />
                        )}
                        {column.render ? column.render(tVal, d) : tVal}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </RsTable>
    </div>
  );
};

export default Table;
