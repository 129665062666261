import cn from 'classnames';
import React from 'react';
import classes from './Tabs.module.scss';

const Tabs = ({
  className,
  items,
  activeTab,
  onChange,
  headerExtra,
  hideHeaderBorder,
  only1Header,
  headerFullWidth,
  headerSize,
}) => {
  const tActiveTab = activeTab || items?.[0]?.key;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.hideHeaderBorder]: hideHeaderBorder,
          [classes.only1Header]: only1Header,
          [classes.headerFullWidth]: headerFullWidth,
        },
        classes[`headerSize_${headerSize}`],
        className
      )}
    >
      <div className={classes.header}>
        <div className={classes.headerInner}>
          {items.map((item) => {
            return (
              <div
                className={cn(classes.headerItem, {
                  [classes.active]: String(tActiveTab) === String(item.key),
                })}
                key={item.key}
              >
                <span
                  onClick={() => {
                    if (onChange) onChange(item.key);
                  }}
                >
                  {item.title}
                </span>
              </div>
            );
          })}
        </div>
        {!!headerExtra && (
          <div className={classes.headerExtra}>{headerExtra}</div>
        )}
      </div>
      <div className={classes.content}>
        {items.map((item) => {
          return (
            <div
              key={item.key}
              className={cn(classes.tabContent, {
                [classes.active]: String(tActiveTab) === String(item.key),
              })}
            >
              {item.content}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
