import React, { useCallback, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';

export default function Suggest(props) {
  const allSuggestions = props.allSuggestions;

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? allSuggestions
      : allSuggestions.filter(
          (s) =>
            value == '' ||
            (s.text.toLowerCase().slice(0, inputLength) === inputValue &&
              inputValue !== s.text.toLowerCase())
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.text;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => <div>{suggestion.text}</div>;

  const [value, setValue] = useState(props.value || '');
  const [suggestions, setSuggestions] = useState(allSuggestions);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: props.hint,
    value,
    onChange: onChange,
  };

  return (
    <span>
      <Autosuggest
        counter={props.counter}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: props.hint,
          value: props.value || '',
          onChange: onChange,
        }}
        // alwaysRenderSuggestions={value==''}
        // shouldKeepSuggestionsOnSelect={(s)=>false}
        shouldRenderSuggestions={(s) => true}
      />
    </span>
  );
}
