import { useState } from 'react';
import Button from 'src/components/Button';
import Input from 'src/components/FormFields/Input';
import Modal from 'src/components/Modal';
import classes from './ArchiveLocationModal.module.scss';

const ArchiveLocationModal = ({ isOpen, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState('');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Archive Location"
      hideClose
      centered
      className={classes.modalWrapper}
    >
      <div className={classes.description}>
        <span>
          You are about to archive this location. While the real time score and
          location record is deactivated we do not delete users, staff or any
          location information. If you’d like to re-enable a location, go to the
          archived locations list and select Re-activate Location. This will
          queue the location reactivation for approval from your supervisor
          <br />
          <br />
          To continue, please enter a reason for archiving this location in the
          field below
        </span>
      </div>
      <div>
        <Input
          placeholder="Enter the reason for archiving this location"
          value={val}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          type="textarea"
          label="Archive Reason"
          radius="md"
          variant="primary"
        />
      </div>
      <div className={classes.modalFooter}>
        <Button
          variant="primary"
          type="button"
          fontSize="sm"
          onClick={onClose}
          isLink
        >
          Cancel
        </Button>
        <Button
          variant="red"
          type="button"
          fontSize="sm"
          loading={loading}
          onClick={() => {
            setLoading(true);
            onSubmit(val, () => {
              setLoading(false);
            });
          }}
          disabled={!(val || '').trim()}
        >
          Archive Location
        </Button>
      </div>
    </Modal>
  );
};

export default ArchiveLocationModal;
