import pick from 'lodash/pick';
import { useEffect, useRef, useState } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import Filter from 'src/components/Filter';
import SearchInput from 'src/components/FormFields/Input/SearchInput';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import Table from 'src/components/Table';
import CreateLocationModal from 'src/pages/CreateRegion/components/CreateLocationModal';
import { getLocationPayload } from 'src/pages/CreateRegion/utils';
import RegionCard from 'src/pages/Regions/components/Card';
import { notifyPrimary } from 'src/utils/notification';
import classes from './RegionDetails.module.scss';
import ArchiveLocationModal from './components/ArchiveLocationModal';
import { getColumns } from './constants';
import { useActions, useIndexData } from './selectorData';

const RegionDetails = () => {
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const regionId = Number(params.id || 0);
  const {
    getRegionDetails,
    updateRegion,
    createLocation,
    syncUpdateRegionDetails,
  } = useActions();
  const { regionDetails, regionDetailsLoading } = useIndexData();
  const [archiveLocationObj, setArchiveLocationObj] = useState(null);
  const [isAddLocation, setIsAddLocation] = useState(false);
  const [createLocationLoading, setCreateLocationLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [checkedRows, setCheckedRows] = useState([]);
  const currentLocations = regionDetails.locations || [];
  const currentLocationIds = currentLocations.map((obj) => obj.id);
  const filteredLocations = currentLocations.filter((obj) =>
    obj.name.toLowerCase().includes(search.toLowerCase())
  );
  const breadcrumbs = [
    {
      link: '/regions',
      label: 'Regions & Locations',
    },
    {
      label: regionDetails.name,
    },
  ];
  const handleEditLocation = () => {};
  const handleArchiveLocation = (obj) => {
    setArchiveLocationObj(obj);
  };
  const columns = getColumns({
    onEdit: handleEditLocation,
    onArchive: handleArchiveLocation,
  });
  const notify = (type, title, description) => {
    notifyPrimary({
      ref: notificationAlertRef,
      type,
      title,
      description,
      settings: {
        place: 'bc',
      },
    });
  };
  const handleUploadRegion = (ids, cb) => {
    updateRegion(
      {
        ...pick(regionDetails, ['id', 'name', 'description']),
        location_ids: ids,
      },
      cb
    );
  };
  const handleCreateLocation = (values) => {
    if (!createLocationLoading) {
      setCreateLocationLoading(true);
      const tPayload = getLocationPayload(values);
      createLocation(tPayload, (res) => {
        if (res) {
          const newLocation = res?.data || {};
          handleUploadRegion([...currentLocationIds, newLocation.id]);
          syncUpdateRegionDetails({
            locations: [...currentLocations, newLocation],
          });
          setCreateLocationLoading(false);
          setIsAddLocation(false);
          notify('success', 'Success', 'Location created successfully');
        } else {
          notify('error', 'Error', 'Location creating failed');
        }
      });
    }
  };

  useEffect(() => {
    getRegionDetails(regionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {regionDetailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <div className={classes.pageTitleWrapper}>
          <PageTitle
            subTitle={
              <>
                The description of this region provides an overview of its
                unique characteristics, highlighting key features and details
                that could cover several lines...
              </>
            }
          >
            {regionDetails.name}
          </PageTitle>
        </div>
        <div className={classes.overviewWrapper}>
          <RegionCard
            hideActions
            type="map"
            title="Overview"
            subHeading="The Idaho region consists for 8 locations across the state . These facilities are primarily cold storage for dairy products."
          />
          <RegionCard
            onDelete={() => {}}
            onEdit={() => {}}
            data={regionDetails}
            type="score"
            hideView
            title="Omni Score"
            dropdowns={[
              {
                key: 'action',
                label: 'Action',
                onClick: () => {},
              },
            ]}
          />
          <RegionCard
            onDelete={() => {}}
            onEdit={() => {}}
            data={regionDetails}
            type="issue"
            hideView
            title="Current Issues"
            dropdowns={[
              {
                key: 'action',
                label: 'Action',
                onClick: () => {},
              },
            ]}
          />
        </div>
        <div className={classes.locations}>
          <div className={classes.locationHeader}>
            <span>Locations List</span>
            <div>
              <SearchInput
                size="medium"
                placeholder="Search Locations"
                iconPosition="right"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Filter />
              <Button
                variant="primary"
                onClick={() => {
                  setIsAddLocation(true);
                }}
                fontSize="sm"
                size="medium"
              >
                Add New
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            data={filteredLocations}
            checkable={{
              checkedRows,
              onChange: (cRows) => {
                setCheckedRows(cRows);
              },
            }}
          />
        </div>
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {archiveLocationObj && (
        <ArchiveLocationModal
          isOpen
          onClose={() => {
            setArchiveLocationObj(null);
          }}
          onSubmit={(val, cb) => {
            // TODO: delete location with reason as val
            handleUploadRegion(
              currentLocationIds.filter((tId) => tId !== archiveLocationObj.id),
              () => {
                syncUpdateRegionDetails({
                  locations: currentLocations.filter(
                    (obj) => obj.id !== archiveLocationObj.id
                  ),
                });
                setArchiveLocationObj(null);
                if (cb) cb();
                notify('success', 'Success', 'Location has been archived');
              }
            );
          }}
        />
      )}
      {isAddLocation && (
        <CreateLocationModal
          isOpen
          onClose={() => {
            setIsAddLocation(false);
          }}
          onSubmit={handleCreateLocation}
          loading={createLocationLoading}
        />
      )}
    </>
  );
};

export default RegionDetails;
