import Request from './request';

export const apiLogin = async (data) => {
  return Request.call({
    url: '/api/login',
    method: 'POST',
    data,
  });
};
export const apiGenerate2FA = async (token) => {
  return Request.call({
    url: '/api/2fa',
    method: 'GET',
    token,
  });
};
export const apiVerify2FACode = async (data, token) => {
  return Request.call({
    url: '/api/2fa/verify',
    method: 'POST',
    data,
    token,
  });
};
export const apiForgotPassword = async (data) => {
  return Request.call({
    url: '/api/forgot-password',
    method: 'POST',
    data,
  });
};
export const apiResetPassword = async (data) => {
  return Request.call({
    url: '/api/reset-password',
    method: 'POST',
    data,
  });
};
export const apiGetCurrentUser = async () => {
  return Request.call({
    url: '/api/profile',
    method: 'GET',
  });
};
