import cn from 'classnames';
import classes from './Score.module.scss';

const ScoreColor = ({ className, color }) => {
  return (
    <span
      className={cn(classes.scoreColor, className)}
      style={{ background: color }}
    ></span>
  );
};

export default ScoreColor;
