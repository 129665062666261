import pick from 'lodash/pick';
import { useEffect, useRef, useState } from 'react';
import NotificationAlert from 'react-notification-alert';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import Filter from 'src/components/Filter';
import SearchInput from 'src/components/FormFields/Input/SearchInput';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import Table from 'src/components/Table';
import Tabs from 'src/components/Tabs';
import Tag from 'src/components/Tag';
import { getLocationPayload } from 'src/pages/CreateRegion/utils';
import RegionCard from 'src/pages/Regions/components/Card';
import { REGION_TAB_KEYS } from 'src/pages/Regions/constants';
import { notifyPrimary } from 'src/utils/notification';
import classes from './LocationDetails.module.scss';
import TabContent from './components/TabContent';
import { LOCATION_DETAILS_TABS, LOCATION_DETAILS_TAB_KEYS } from './constants';
import { useActions, useIndexData } from './selectorData';

const LocationDetails = () => {
  const notificationAlertRef = useRef(null);
  const params = useParams();
  const locationId = Number(params.id || 0);
  const { getRegionDetails } = useActions();
  const { regionDetails, regionDetailsLoading } = useIndexData();
  const [activeTab, setActiveTab] = useState();
  const breadcrumbs = [
    {
      link: `/regions?tab=${REGION_TAB_KEYS.LOCATIONS}`,
      label: 'Regions & Locations',
    },
    {
      // label: regionDetails.name,
      label: 'Location Name',
    },
  ];
  const items = LOCATION_DETAILS_TABS.map((obj) => {
    return {
      key: obj.key,
      title: obj.title,
      content: <TabContent tabKey={obj.key} />,
    };
  });

  useEffect(() => {
    getRegionDetails(locationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {regionDetailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <div className={classes.pageTitleWrapper}>
          <PageTitle
            subTitle={
              <>
                An Americold refrigeration facility specializes in
                temperature-controlled storage and logistics, supporting the
                distribution of perishable goods efficiently and reliably.
              </>
            }
          >
            {/* {regionDetails.name} */}
            <span>Americold Logistics Nampa</span>
            <Tag>Region A</Tag>
          </PageTitle>
        </div>
        <div className={classes.overviewWrapper}>
          <RegionCard
            onDelete={() => {}}
            onEdit={() => {}}
            data={regionDetails}
            type="score"
            hideView
            title="Omni Score"
            hideActions
            isLocation
          />
          <RegionCard hideActions type="map" title="Location" />
        </div>
        <Tabs
          items={items}
          activeTab={activeTab}
          onChange={(val) => {
            setActiveTab(val);
          }}
          headerFullWidth
          headerSize="sm"
        />
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default LocationDetails;
