import cn from 'classnames';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import { forwardRef, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { Label } from 'reactstrap';
import { ReactComponent as TickCircleSvg } from 'src/assets/icons/tick-circle.svg';
import { SELECT_CREATE_OPTION } from 'src/helper/constants';

import classes from './Select.module.scss';

const MultiValueContainer = (props) => {
  const selectedValue = get(props, 'data.value', '');
  const selectedLabel = get(props, 'data.label', '');
  const selectedValues = get(props, 'selectProps.value') || [];
  const multiLabel = get(props, 'selectProps.multiLabel', '');
  const isFirst = findIndex(selectedValues, { value: selectedValue }) === 0;
  const valuesLength = selectedValues.length;
  if (valuesLength === 1) {
    return <span className={classes.selectedValue}>{selectedLabel}</span>;
  }
  return (
    <span
      className={!isFirst ? classes.hide : ''}
    >{`${valuesLength} ${multiLabel}`}</span>
  );
};
const SingleValue = ({ children, ...props }) => {
  const { data } = props;
  const { obj = {} } = data;
  const { icon } = obj;
  return (
    <components.SingleValue {...props}>
      {children} {icon || ''}
    </components.SingleValue>
  );
};
const MenuList = (props) => {
  const menuListRef = useRef(null);
  useEffect(() => {
    if (menuListRef.current) {
      menuListRef.current.querySelector('div').onscroll = () => {
        // TODO: handle onScroll
      };
    }
  }, [menuListRef]);

  return (
    <div className={classes.menuWithCheckbox} ref={menuListRef}>
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </div>
  );
};
const OptionSelectedWithCheck = (props) => {
  const { label } = props;
  return (
    <components.Option {...props}>
      {props.isSelected ? <TickCircleSvg /> : ''}
      <span>{label}</span>
    </components.Option>
  );
};
const customStyles = (props) => {
  const { hideArrow } = props;
  return {
    control: (bases, state) => {
      return {
        ...bases,
        borderColor: state.isFocused ? '#06402B' : '#92919d',
        boxShadow: state.isFocused ? '0px 0px 0px 2px #09624233' : undefined,
        minHeight: '44px',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#09090B',
        fontWeight: 400,
        '&:hover': {
          borderColor: '#06402B',
          boxShadow: '0px 0px 0px 2px #09624233',
        },
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        cursor: state.isDisabled ? 'default' : 'pointer',
        background: state.isFocused || state.isSelected ? '#E6EFE8' : undefined,
        fontWeight: state?.data?.value === SELECT_CREATE_OPTION ? 400 : 500,
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '12px 16px',
        fontSize: '14px',
        '&:hover': {
          background: '#E6EFE8',
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        padding: 0,
      };
    },
    noOptionsMessage: (bases) => {
      return {
        ...bases,
        '&:before': {
          display: 'none !important',
        },
        '&:after': {
          display: 'none !important',
        },
      };
    },
    clearIndicator: (bases) => {
      return {
        ...bases,
        cursor: 'pointer',
      };
    },
    dropdownIndicator: (bases) => {
      return {
        ...bases,
        display: hideArrow ? 'none' : undefined,
      };
    },
    indicatorSeparator: () => ({ display: 'none' }),
    menuPortal: (base) => ({ ...base, zIndex: 1060 }),
  };
};

const ReSelect = forwardRef((props, ref) => {
  const {
    isCreate,
    isValueHasIcon,
    selectedWithCheck,
    label,
    className,
    ...other
  } = props;
  let selectComponents = {
    MultiValueContainer,
    MenuList,
  };
  if (isValueHasIcon) {
    selectComponents = {
      ...selectComponents,
      SingleValue,
    };
  }
  if (selectedWithCheck) {
    selectComponents = {
      ...selectComponents,
      Option: OptionSelectedWithCheck,
    };
  }
  return (
    <div className={cn(classes.wrapper, className)}>
      {!!label && <Label className={classes.label}>{label}</Label>}
      {isCreate ? (
        <Creatable
          ref={ref}
          hideSelectedOptions={false}
          menuPosition="fixed"
          {...other}
          className={classes.wrapper}
          styles={customStyles(props)}
          components={selectComponents}
        />
      ) : (
        <Select
          ref={ref}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          menuPosition="fixed"
          {...other}
          className={classes.wrapper}
          styles={customStyles(props)}
          components={selectComponents}
        />
      )}
    </div>
  );
});

export default ReSelect;
