import cn from 'classnames';
import { FormGroup as ReactstrapFormGroup } from 'reactstrap';
import classes from './FormGroup.module.scss';

const FormGroup = ({ className, ...props }) => {
  return (
    <ReactstrapFormGroup
      className={cn(classes.wrapper, className)}
      {...props}
    />
  );
};

export default FormGroup;
