import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import classes from './TurnOffModal.module.scss';

const TurnOffModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Regions Hidden"
      hideClose
      centered
    >
      <div>
        <span>
          You have hidden regions from use in your company. If you have a need
          for them in the future, go to Settings under the preferences tab to
          turn them back on.
        </span>
      </div>
      <div className={classes.modalFooter}>
        <Button variant="primary" type="button" fontSize="sm" onClick={onClose}>
          I understand
        </Button>
      </div>
    </Modal>
  );
};

export default TurnOffModal;
