import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight1Svg } from 'src/assets/icons/arrow-right1.svg';
import classes from './Breadcrumb.module.scss';

const Breadcrumb = ({ className, items }) => {
  const tItems = items || [];
  if (items.length === 0) return '';
  return (
    <div className={cn(classes.wrapper, className)}>
      <ul>
        {tItems.map((item, i) => {
          return (
            <li key={i}>
              {item.link ? (
                <Link to={item.link}>{item.label}</Link>
              ) : (
                <span>{item.label}</span>
              )}
              {i < tItems.length - 1 && (
                <span>
                  <ArrowRight1Svg />
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Breadcrumb;
