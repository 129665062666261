import cn from 'classnames';
import { forwardRef } from 'react';
// import { Input, Label } from 'reactstrap';
import Tooltip from 'src/components/Tooltip';
import classes from './Switch.module.scss';

const Switch = forwardRef(
  ({ className, label, disabled, tooltip, checked, onChange }, ref) => {
    const el = (
      <label className="custom-toggle">
        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          ref={ref}
        />
        <span className="custom-toggle-slider rounded-circle" />
      </label>
    );
    return (
      <div
        className={cn(
          classes.wrapper,
          {
            [classes.disabled]: disabled,
          },
          className
        )}
      >
        <div>
          {!!label && <label className={classes.label}>{label}</label>}
          {tooltip ? (
            <Tooltip placement="bottom" tooltipText={tooltip}>
              {el}
            </Tooltip>
          ) : (
            el
          )}
        </div>
      </div>
    );
  }
);

export default Switch;
