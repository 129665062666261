import * as locationActions from 'src/store/actions/location';
import * as regionActions from 'src/store/actions/region';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    allLocations: useReducerData('location', 'allLocations.data', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...regionActions,
    ...locationActions,
  });
};
