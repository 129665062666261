import ButtonEdge from './ButtonEdge';

export const initialEdges = [
  {
    id: '0.3223508839655045',
    source: 'a',
    target: '0.3223508839655045',
    type: 'button-edge',
  },
  {
    id: '0.37154557711294767',
    source: '0.3223508839655045',
    target: '0.37154557711294767',
    type: 'button-edge',
  },
  {
    id: '0.20717839638869662',
    source: 'a',
    target: '0.20717839638869662',
    type: 'button-edge',
  },
  {
    id: '0.8129624492906533',
    source: '0.20717839638869662',
    target: '0.8129624492906533',
    type: 'button-edge',
  },
  {
    id: '0.4261102818047706',
    source: 'a',
    target: '0.4261102818047706',
    type: 'button-edge',
  },
  {
    id: '0.8355292323542867',
    source: '0.4261102818047706',
    target: '0.8355292323542867',
    type: 'button-edge',
  },
  {
    id: '0.2275574927660553',
    source: 'a',
    target: '0.2275574927660553',
    type: 'button-edge',
  },
  {
    id: '0.7515202632122984',
    source: '0.2275574927660553',
    target: '0.7515202632122984',
    type: 'button-edge',
  },
  {
    id: '0.24146711933971177',
    source: '0.8355292323542867',
    target: '0.24146711933971177',
    type: 'button-edge',
  },
  {
    type: 'button-edge',
    source: '0.7515202632122984',
    target: '0.24146711933971177',
    id: 'xy-edge__0.7515202632122984-0.24146711933971177',
  },
  {
    type: 'button-edge',
    source: '0.8129624492906533',
    target: '0.24146711933971177',
    id: 'xy-edge__0.8129624492906533-0.24146711933971177',
  },
  {
    id: '0.0025609993713409107',
    source: 'a',
    target: '0.0025609993713409107',
    type: 'button-edge',
  },
  {
    id: '0.7364752503511536',
    source: '0.0025609993713409107',
    target: '0.7364752503511536',
    type: 'button-edge',
  },
  {
    id: '0.12487094503763907',
    source: 'a',
    target: '0.12487094503763907',
    type: 'button-edge',
  },
  {
    id: '0.401245858839685',
    source: '0.12487094503763907',
    target: '0.401245858839685',
    type: 'button-edge',
  },
  {
    type: 'button-edge',
    source: '0.401245858839685',
    target: '0.24146711933971177',
    id: 'xy-edge__0.401245858839685-0.24146711933971177',
  },
  {
    id: '0.5274840045236684',
    source: '0.24146711933971177',
    target: '0.5274840045236684',
    type: 'button-edge',
  },
  {
    id: '0.9345908392068762',
    source: '0.5274840045236684',
    target: '0.9345908392068762',
    type: 'button-edge',
  },
  {
    type: 'button-edge',
    source: 'a',
    target: '0.38396505954081395',
    id: 'xy-edge__a-0.38396505954081395',
  },
  {
    id: '0.9894621993453097',
    source: '0.38396505954081395',
    target: '0.9894621993453097',
    type: 'button-edge',
  },
  {
    id: '0.029002907603363104',
    source: '0.9894621993453097',
    target: '0.029002907603363104',
    type: 'button-edge',
  },
  {
    id: '0.30985942933521526',
    source: '0.029002907603363104',
    target: '0.30985942933521526',
    type: 'button-edge',
  },
  {
    id: '0.9288051616630053',
    source: '0.30985942933521526',
    target: '0.9288051616630053',
    type: 'button-edge',
  },
  {
    id: '0.5587243344693775',
    source: '0.029002907603363104',
    target: '0.9165217305094666',
    type: 'button-edge',
  },
  {
    id: '0.057734777885599',
    source: '0.9165217305094666',
    target: '0.8675627438813485',
    type: 'button-edge',
  },
  {
    type: 'button-edge',
    source: '0.9288051616630053',
    target: '0.8675627438813485',
    id: 'xy-edge__0.9288051616630053-0.8675627438813485',
  },
];

export const edgeTypes = {
  // Add your custom edge types here!
  'button-edge': ButtonEdge,
};
