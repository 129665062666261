import { useState } from 'react';
import { ReactComponent as DangerSvg } from 'src/assets/icons/danger.svg';
import Button from 'src/components/Button';
import Input from 'src/components/FormFields/Input';
import Modal from 'src/components/Modal';
import { DELETE_REGION_HAS_LOCATION_PREFIX } from 'src/helper/constants';
import classes from './DeleteModal.module.scss';

const DeleteModal = ({
  isOpen,
  onClose,
  title,
  onSubmit,
  deleteId,
  onEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState('');
  const tDeleteId = deleteId || '';
  const arr =
    typeof tDeleteId === 'string' &&
    String(tDeleteId).includes(DELETE_REGION_HAS_LOCATION_PREFIX)
      ? tDeleteId.replace(DELETE_REGION_HAS_LOCATION_PREFIX, '').split('_-')
      : [];
  const numberOfLocations = Number(arr[0] || 0);
  const hasLocations =
    String(tDeleteId).includes(DELETE_REGION_HAS_LOCATION_PREFIX) &&
    numberOfLocations > 0;
  const regionName = arr[1];
  const tId = Number(arr[2] || 0);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} hideClose centered>
      <div className={classes.description}>
        <span>
          {hasLocations ? (
            <>
              Regions with locations attached to them cannot be deleted. Please
              reassign all associated locations from <b>{regionName}</b> before
              deleting the region.
            </>
          ) : (
            <>
              Deleting a Region is permanent and cannot be undone. Are you sure
              you want to delete this region?
              <br />
              <br />
              Please type DELETE in the field below to proceed.
            </>
          )}
        </span>
      </div>
      <div>
        {hasLocations ? (
          <div className={classes.alert}>
            <DangerSvg />
            <span>
              There are {numberOfLocations} assigned location
              {numberOfLocations === 1 ? '' : 's'} to this region
            </span>
          </div>
        ) : (
          <Input
            placeholder="DELETE"
            value={val}
            onChange={(e) => {
              setVal(e.target.value);
            }}
          />
        )}
      </div>
      <div className={classes.modalFooter}>
        <Button
          variant="primary"
          type="button"
          fontSize="sm"
          onClick={
            hasLocations
              ? () => {
                  if (onEdit) onEdit(tId);
                }
              : onClose
          }
          isLink
        >
          {hasLocations ? 'Edit Region' : 'Cancel'}
        </Button>
        <Button
          variant={hasLocations ? 'primary' : 'red'}
          type="button"
          fontSize="sm"
          loading={loading}
          onClick={
            hasLocations
              ? onClose
              : () => {
                  setLoading(true);
                  onSubmit(() => {
                    setVal('');
                    setLoading(false);
                  });
                }
          }
          disabled={hasLocations ? false : (val || '').trim() !== 'DELETE'}
        >
          {hasLocations ? 'Done' : 'Delete Region'}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
