import Breadcrumb from 'src/components/Breadcrumb';
import Switch from 'src/components/FormFields/Switch';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import classes from './Settings.module.scss';
import { useActions, useIndexData } from './selectorData';

const Settings = () => {
  const { updateSettings, syncUpdateSettings } = useActions();
  const { settings, regions } = useIndexData();
  const isEnableRegions = !!settings.find((obj) => obj.key === 'enable_regions')
    ?.value;
  const hasRegions = regions?.total > 0;
  const breadcrumbs = [
    {
      label: 'Settings',
    },
  ];

  return (
    <PageWrapper className={classes.wrapper}>
      <Breadcrumb items={breadcrumbs} />
      <PageTitle>Settings</PageTitle>
      <div>
        <span>Adjust settings for your company</span>
      </div>
      <div className={classes.toggleRegions}>
        <Switch
          disabled={hasRegions}
          checked={isEnableRegions}
          onChange={(e) => {
            syncUpdateSettings('enable_regions', e.target.checked);
            updateSettings({
              settings: {
                enable_regions: e.target.checked,
              },
            });
          }}
          tooltip={
            hasRegions ? (
              <>
                Please remove all regions
                <br />
                before turning regions off
              </>
            ) : undefined
          }
          label={
            <>
              <span>Regions</span>
              <span>Use regions to help organize your locations</span>
            </>
          }
        />
      </div>
    </PageWrapper>
  );
};

export default Settings;
