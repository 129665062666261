import { createTypes } from './createTypes';

export default createTypes(
  'SYNC_ADD_ALL_LOCATIONS',
  'GET_ALL_LOCATIONS',
  'GET_LOCATIONS',
  'GET_LOCATION_DETAILS',
  'CREATE_LOCATION',
  'UPDATE_LOCATION',
  'DELETE_LOCATION'
);
