import cn from 'classnames';
import { forwardRef } from 'react';
import classes from './Checkbox.module.scss';

const Checkbox = forwardRef(
  ({ className, label, id, variant, ...props }, ref) => {
    return (
      <div
        className={cn(
          'custom-control custom-control-alternative custom-checkbox',
          classes.wrapper,
          classes[`variant_${variant}`],
          className
        )}
      >
        <input
          className="custom-control-input"
          type="checkbox"
          id={id}
          ref={ref}
          {...props}
        />
        <label className="custom-control-label" htmlFor={id}>
          <span>{label}</span>
        </label>
      </div>
    );
  }
);

export default Checkbox;
