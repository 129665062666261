import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
// import HomeMarkerPng from 'src/assets/img/home_marker.png';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import Input from 'src/components/FormFields/Input';
import FormInput from 'src/components/FormFields/Input/FormInput';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import { WEEK_DAY, WEEK_DAYS } from 'src/helper/constants/date';
import useCurrentPath from 'src/helper/hooks/userCurrentPath';
import { REGION_TAB_KEYS } from 'src/pages/Regions/constants';
import { notifyPrimary } from 'src/utils/notification';
import classes from './CreateLocation.module.scss';
import OperatingHours from './components/OperatingHours';
import { DEFAULT_EDIT_OBJ } from './constants';

const formSchema = yup.object().shape({
  name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  description: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
});

const DEFAULT_LOCATION = {
  lat: 44.295894,
  lng: -114.736846,
};

const CreateLocation = () => {
  const params = useParams();
  const locationId = String(params.id || '');
  const isEdit = !!locationId;
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const notificationAlertRef = useRef(null);
  const [createRegionLoading, setCreateRegionLoading] = useState(false);
  const [createLocationLoading, setCreateLocationLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [sourceSearchResult, setSourceSearchResult] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(DEFAULT_LOCATION);
  const [locationAddress, setLocationAddress] = useState('');
  const breadcrumbs = [
    {
      link: `/regions?tab=${REGION_TAB_KEYS.LOCATIONS}`,
      label: 'Regions & Locations',
    },
    {
      label: isEdit ? 'Edit Location' : 'Create Location',
    },
  ];
  const defaultValues = {
    name: isEdit ? DEFAULT_EDIT_OBJ.name : '',
    description: isEdit ? DEFAULT_EDIT_OBJ.description : '',
    overview: isEdit ? DEFAULT_EDIT_OBJ.overview : '',
  };
  WEEK_DAYS.map((day) => {
    defaultValues[`${day}_day`] = day !== WEEK_DAY.SUNDAY;
  });
  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      ...defaultValues,
    },
  });
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const onSubmit = async (values) => {
    navigate(-1);
    if (!createRegionLoading) {
    }
  };
  const handleCreateLocation = async (values) => {
    if (!createLocationLoading) {
    }
  };
  const onLoadSource = (autocomplete) => {
    setSourceSearchResult(autocomplete);
  };
  const onSourceChanged = () => {
    if (sourceSearchResult != null) {
      const place = sourceSearchResult.getPlace();
      setCurrentLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setLocationAddress(place.formatted_address);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setLocationAddress('231 4th Ave N, Nampa, ID 83687');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {detailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          className={classes.pageTitle}
          subTitle={
            <>
              Locations for categorizing the different physical locations of
              your company. Sub-systems,
              <br />
              equipment and documents are generally tied to a specific location
              and can be added at a later time.
            </>
          }
        >
          {isEdit ? 'Edit Location' : 'Create Location'}
        </PageTitle>
        <form
          role="form"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.formInner}>
            <div>
              <FormGroup>
                <FormInput
                  label="Location Name"
                  name="name"
                  placeholder="Enter location name"
                  control={control}
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  autoFocus
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  label="Location High Level Description"
                  name="description"
                  placeholder="Short description of the location that appears on the overview"
                  control={control}
                  type="textarea"
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  className={classes.descriptionInput}
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  label="Location System Overview"
                  name="overview"
                  placeholder="A detailed description of the location, where it’s located and info about the sub-systems"
                  control={control}
                  type="textarea"
                  radius="md"
                  variant="primary"
                  fontSize="sm"
                  className={classes.overviewInput}
                />
              </FormGroup>
              <div className={classes.locationWrapper}>
                <Autocomplete
                  onPlaceChanged={onSourceChanged}
                  onLoad={onLoadSource}
                >
                  <Input
                    label="Location Address"
                    name="address"
                    placeholder="Enter location address"
                    radius="md"
                    variant="primary"
                    fontSize="sm"
                    value={locationAddress}
                    onChange={(e) => setLocationAddress(e.target.value)}
                  />
                </Autocomplete>
                <div className={classes.map}>
                  <GoogleMap
                    center={currentLocation}
                    zoom={15}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    options={{
                      zoomControl: true,
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                    }}
                    // onLoad={(map) => setMap(map)}
                  >
                    {/* <Marker position={currentLocation} icon={HomeMarkerPng} /> */}
                    <Marker position={currentLocation} />
                    {/* {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )} */}
                  </GoogleMap>
                </div>
              </div>
            </div>
            <OperatingHours control={control} />
          </div>
          <div className={classes.formFooter}>
            <Button
              variant="primary"
              isLink
              type="button"
              onClick={() => {
                navigate(`/regions?tab=${REGION_TAB_KEYS.LOCATIONS}`);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!formState.isValid || detailsLoading}
              loading={createRegionLoading}
            >
              {isEdit ? 'Edit Location' : 'Create Location'}
            </Button>
          </div>
        </form>
      </PageWrapper>
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default CreateLocation;
