// eslint-disable-next-line no-unused-vars
import cn from 'classnames';
import React from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import classes from './Dashboard.module.scss';

const Dashboard = () => {
  const breadcrumbs = [
    {
      link: '/',
      label: 'Previous Page',
    },
    {
      link: '/',
      label: 'Previous Page',
    },
    {
      label: 'Current Page',
    },
  ];
  return (
    <PageWrapper className={classes.wrapper}>
      <Breadcrumb items={breadcrumbs} />
      <PageTitle>Page Title Goes Here</PageTitle>
      <div>Subcopy goes here if applicable</div>
    </PageWrapper>
  );
};

export default Dashboard;
