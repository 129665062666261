import cn from 'classnames';
import { ReactComponent as CloseCircleSvg } from 'src/assets/icons/close-circle.svg';
import classes from './AddedLocation.module.scss';

const AddedLocation = ({ className, onRemove, label, description }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div>
        <span>{label}</span>
        <CloseCircleSvg onClick={onRemove} />
      </div>
      <div>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default AddedLocation;
