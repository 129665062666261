import cn from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArchiveLocationModal from 'src/pages/RegionDetails/components/ArchiveLocationModal';
import EmptyState from 'src/pages/Regions/components/EmptyState';
import LocationCard from 'src/pages/Regions/components/LocationCard';
import classes from './Locations.module.scss';

const Locations = ({ notify }) => {
  const [archiveLocationObj, setArchiveLocationObj] = useState(null);
  const navigate = useNavigate();
  const locations = [{ id: 1 }, { id: 2 }];
  const isNotFound = locations.length === 0;

  return (
    <>
      <div className={cn(classes.wrapper)}>
        <div>
          <span className={classes.description}>
            Select a location to view details or make adjustments
          </span>
        </div>
        {isNotFound ? (
          <EmptyState
            entity="location"
            onCreate={() => {
              navigate('/locations/create-location');
            }}
          />
        ) : (
          <div className={classes.locations}>
            {locations.map((location, i) => {
              return (
                <LocationCard
                  key={i}
                  onDelete={() => {
                    setArchiveLocationObj({});
                  }}
                  onEdit={() => {
                    navigate(`/locations/edit/${location.id}`);
                  }}
                  data={location}
                  onView={() => {
                    navigate(`/locations/${location.id}`);
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
      {archiveLocationObj && (
        <ArchiveLocationModal
          isOpen
          onClose={() => {
            setArchiveLocationObj(null);
          }}
          onSubmit={(val, cb) => {
            if (cb) cb();
            setArchiveLocationObj(null);
            notify('success', 'Success', 'Location archived successfully', {
              place: 'bc',
            });
            // TODO: delete location with reason as val
          }}
        />
      )}
    </>
  );
};

export default Locations;
