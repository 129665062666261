import { combineReducers } from 'redux';

import AuthReducer from './auth';
import LocationReducer from './location';
import RegionReducer from './region';
import SettingReducer from './setting';

const reducers = {
  auth: AuthReducer,
  setting: SettingReducer,
  location: LocationReducer,
  region: RegionReducer,
};

const combinedReducer = combineReducers(reducers);

export default combinedReducer;
