import Button from 'src/components/Button';
import FormSelect from 'src/components/FormFields/Select/FormSelect';
import { SELECT_CREATE_OPTION } from 'src/helper/constants';
import AddedLocation from 'src/pages/CreateRegion/components/AddedLocation';
import { getFullAddress } from 'src/utils/address';
import classes from './SelectLocation.module.scss';

const SelectLocation = ({
  control,
  selectedLocations,
  addedLocations,
  setValue,
  onCreateLocation,
  options,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Locations</div>
      <div className={classes.selectLocations}>
        <FormSelect
          label="Add Location"
          hideArrow
          options={options.filter(
            (obj) => addedLocations.findIndex((o) => o.value === obj.value) < 0
          )}
          placeholder="Search locations"
          isMulti
          selectedWithCheck
          hasCreateOption
          closeMenuOnSelect={false}
          multiLabel="locations selected"
          control={control}
          menuPosition={'fixed'}
          name="locations"
          onChange={(val) => {
            const lastValue = val?.[val?.length - 1]?.value;
            if (lastValue === SELECT_CREATE_OPTION) {
              if (onCreateLocation) onCreateLocation();
            }
          }}
          filterOption={(option, txt) => {
            return option.value === SELECT_CREATE_OPTION
              ? true
              : option.label.toLowerCase().includes(txt.toLowerCase());
          }}
        />
        <Button
          variant="primary"
          disabled={!selectedLocations?.length}
          onClick={() => {
            setValue('addedLocations', [
              ...addedLocations,
              ...selectedLocations,
            ]);
            setValue('locations', []);
          }}
          fontSize="sm"
        >
          Add to Region
        </Button>
      </div>
      <div className={classes.addedLocations}>
        {addedLocations.map((location) => {
          return (
            <AddedLocation
              key={location.value}
              label={location.label}
              description={getFullAddress(location.address, true)}
              onRemove={() => {
                setValue(
                  'addedLocations',
                  addedLocations.filter((obj) => obj.value !== location.value)
                );
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SelectLocation;
