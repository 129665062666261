import { useState } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import Tabs from 'src/components/Tabs';
import classes from './Archived.module.scss';
import LocationTable from './components/LocationTable';
import TabContent from './components/TabContent';
import { ARCHIVED_TAB_KEYS } from './constants';

const Archived = () => {
  const [activeTab, setActiveTab] = useState(ARCHIVED_TAB_KEYS.LOCATIONS);
  const breadcrumbs = [
    {
      label: 'Archived',
    },
  ];
  const LOCATION_TAB = {
    key: ARCHIVED_TAB_KEYS.LOCATIONS,
    title: 'Location',
    content: (
      <TabContent>
        <span>Archived Locations</span>
        <LocationTable />
      </TabContent>
    ),
  };
  const SUB_SYSTEMS_TAB = {
    key: ARCHIVED_TAB_KEYS.SUB_SYSTEMS,
    title: 'Sub-systems',
    content: (
      <TabContent>
        <span>Archived Sub systems</span>
      </TabContent>
    ),
  };
  const items = [LOCATION_TAB, SUB_SYSTEMS_TAB];

  return (
    <PageWrapper className={classes.wrapper}>
      <Breadcrumb items={breadcrumbs} />
      <PageTitle>Archived</PageTitle>
      <div>
        <span>View items that have been archived</span>
      </div>
      <Tabs
        items={items}
        activeTab={activeTab}
        onChange={(val) => {
          setActiveTab(val);
        }}
        headerFullWidth
        headerSize="sm"
      />
    </PageWrapper>
  );
};

export default Archived;
