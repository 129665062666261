import cn from 'classnames';
import classes from './Tag.module.scss';

const Tag = ({ className, children }) => {
  return (
    <span className={cn(classes.wrapper, className)}>
      <span>{children}</span>
    </span>
  );
};

export default Tag;
