import { createTypes } from './createTypes';

export default createTypes(
  'SYNC_REMOVE_REGIONS',
  'SYNC_UPDATE_REGION_DETAILS',
  'GET_REGIONS',
  'GET_REGION_DETAILS',
  'CREATE_REGION',
  'UPDATE_REGION',
  'DELETE_REGION'
);
