import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/Page/PageTitle';
import PageWrapper from 'src/components/Page/PageWrapper';
import {
  INPUT_HIGHLIGHT_ERROR_MESSAGE,
  SELECT_CREATE_OPTION,
} from 'src/helper/constants';
import useCurrentPath from 'src/helper/hooks/userCurrentPath';
import CreateLocationModal from 'src/pages/CreateRegion/components/CreateLocationModal';
import SelectLocation from 'src/pages/CreateRegion/components/SelectLocation';
import { actionCb } from 'src/utils/actions';
import { notifyPrimary } from 'src/utils/notification';
import classes from './CreateRegion.module.scss';
import { useActions, useIndexData } from './selectorData';
import { getLocationPayload, getRegionPayload } from './utils';

const formSchema = yup.object().shape({
  name: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
  description: yup.string().required(INPUT_HIGHLIGHT_ERROR_MESSAGE),
});

const CreateRegion = () => {
  const {
    createRegion,
    createLocation,
    getAllLocations,
    updateRegion,
    syncAddAllLocations,
    getRegionDetails,
  } = useActions();
  const { allLocations } = useIndexData();
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const params = useParams();
  const regionId = Number(params.id || 0);
  const notificationAlertRef = useRef(null);
  const [isAddLocation, setIsAddLocation] = useState(false);
  const [createRegionLoading, setCreateRegionLoading] = useState(false);
  const [createLocationLoading, setCreateLocationLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const allLocationsList = allLocations?.data || [];
  const isEdit = currentPath === '/regions/edit/:id';
  const locationOptions = [
    ...allLocationsList.map((obj) => ({
      value: obj.id,
      label: obj.name,
    })),
    {
      value: SELECT_CREATE_OPTION,
      label: 'Create New Location',
    },
  ];
  const breadcrumbs = [
    {
      link: '/regions',
      label: 'Regions & Locations',
    },
    {
      label: 'Create Region',
    },
  ];
  const { handleSubmit, control, watch, setValue, formState, reset } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      locations: [],
      addedLocations: [],
    },
  });
  const wSelectedLocations = watch('locations');
  const selectedLocations = wSelectedLocations || [];
  const wAddedLocations = watch('addedLocations');
  const addedLocations = wAddedLocations || [];
  const notify = (type, title, description) => {
    notifyPrimary({ ref: notificationAlertRef, type, title, description });
  };
  const onSubmit = async (values) => {
    if (!createRegionLoading) {
      setCreateRegionLoading(true);
      const tAction = isEdit ? updateRegion : createRegion;
      const tPayload = getRegionPayload(values);
      if (isEdit) {
        tPayload.id = regionId;
      }
      tAction(
        tPayload,
        actionCb(
          () => {
            setCreateRegionLoading(false);
            navigate(
              `/regions?${isEdit ? 'updateSuccess' : 'createSuccess'}=true`
            );
          },
          () => {
            notify(
              'error',
              'Failed',
              `Region ${isEdit ? 'editing' : 'creating'} failed`
            );
            setCreateRegionLoading(false);
          }
        )
      );
    }
  };
  const handleCreateLocation = async (values) => {
    if (!createLocationLoading) {
      setCreateLocationLoading(true);
      const tPayload = getLocationPayload(values);
      createLocation(
        tPayload,
        actionCb(
          (res) => {
            const newLocation = res?.data || {};
            syncAddAllLocations(newLocation);
            setCreateLocationLoading(false);
            setIsAddLocation(false);
            notify('success', 'Success', 'Location created successfully');
          },
          () => {
            setCreateLocationLoading(false);
            notify('error', 'Failed', 'Location creating failed');
          }
        )
      );
    }
  };

  useEffect(() => {
    if (isEdit && regionId) {
      setDetailsLoading(true);
      getRegionDetails(regionId, (res) => {
        const tRegion = res?.data || {};
        if (tRegion.id) {
          const editObj = {
            name: tRegion.name,
            description: tRegion.description,
            addedLocations: (tRegion.locations || []).map((obj) => ({
              value: obj.id,
              label: obj.name,
            })),
          };
          reset(editObj);
        }
        setDetailsLoading(false);
      });
    }
    getAllLocations({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageWrapper className={classes.wrapper}>
        {detailsLoading && <Loading isPage />}
        <Breadcrumb items={breadcrumbs} />
        <PageTitle
          className={classes.pageTitle}
          subTitle={
            <>
              Regions allow you to categorize facilities for more effective
              management. This is an optional level of organization that can be
              toggled from within settings.
            </>
          }
        >
          {isEdit ? 'Edit' : 'Create'} Region
        </PageTitle>
        <form
          role="form"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.formInner}>
            <FormGroup>
              <FormInput
                label="Region Name*"
                name="name"
                placeholder="Enter region name"
                control={control}
                radius="md"
                variant="primary"
                fontSize="sm"
                autoFocus
              />
            </FormGroup>
            <FormGroup>
              <FormInput
                label="Description*"
                name="description"
                placeholder="Enter description"
                control={control}
                type="textarea"
                radius="md"
                variant="primary"
                fontSize="sm"
              />
            </FormGroup>
            <SelectLocation
              control={control}
              selectedLocations={selectedLocations}
              addedLocations={addedLocations.map((obj) => ({
                ...obj,
                ...(allLocationsList.find(
                  (location) => location.id === obj.value
                ) || {}),
              }))}
              setValue={setValue}
              onCreateLocation={() => {
                setIsAddLocation(true);
              }}
              options={locationOptions}
            />
          </div>
          <div className={classes.formFooter}>
            <Button
              variant="primary"
              isLink
              type="button"
              onClick={() => {
                navigate('/regions');
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!formState.isValid || detailsLoading}
              loading={createRegionLoading}
            >
              {isEdit ? 'Save Changes' : 'Create Region'}
            </Button>
          </div>
        </form>
      </PageWrapper>
      {isAddLocation && (
        <CreateLocationModal
          isOpen
          onClose={() => {
            setIsAddLocation(false);
          }}
          onSubmit={handleCreateLocation}
          loading={createLocationLoading}
        />
      )}
      <div className="rna-wrapper primary">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default CreateRegion;
