import Request from './request';

export const apiGetLocations = async (params) => {
  return Request.call({
    url: '/api/locations',
    method: 'GET',
    params,
  });
};
export const apiGetLocationDetails = async (id) => {
  return Request.call({
    url: `/api/locations/${id}`,
    method: 'GET',
  });
};
export const apiCreateLocation = async (data) => {
  return Request.call({
    url: '/api/locations',
    method: 'POST',
    data,
  });
};
export const apiDeleteLocation = async (id) => {
  return Request.call({
    url: `/api/locations/${id}`,
    method: 'DELETE',
    data: {},
  });
};
