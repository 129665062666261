import { ReactComponent as ArchivedSvg } from 'src/assets/icons/Nav/Archived.svg';
import { ReactComponent as ArchivedActiveSvg } from 'src/assets/icons/Nav/Archived_Active.svg';
import { ReactComponent as DashboardSvg } from 'src/assets/icons/Nav/Dashboard.svg';
import { ReactComponent as DashboardActiveSvg } from 'src/assets/icons/Nav/Dashboard_Active.svg';
import { ReactComponent as RegionsSvg } from 'src/assets/icons/Nav/Regions.svg';
import { ReactComponent as RegionsActiveSvg } from 'src/assets/icons/Nav/Regions_Active.svg';
import { ReactComponent as SettingsSvg } from 'src/assets/icons/Nav/Settings.svg';
import { ReactComponent as SettingsActiveSvg } from 'src/assets/icons/Nav/Settings_Active.svg';
import ForgotPassword from 'src/pages/Auth/ForgotPassword';
import Login from 'src/pages/Auth/Login';
import ResetPassword from 'src/pages/Auth/ResetPassword';
import ComponentButtons from 'src/pages/Components/Buttons';
import CreateLocation from 'src/pages/CreateLocation';
import CreateRegion from 'src/pages/CreateRegion';
import Dashboard from 'src/pages/Dashboard';
import LocationDetails from 'src/pages/LocationDetails';
import RegionDetails from 'src/pages/RegionDetails';
import Regions from 'src/pages/Regions';
import Settings from 'src/pages/Settings';
import Archived from './pages/Archived';
import Workflow from './pages/Workflow';

const routes = [
  {
    collapse: true,
    name: 'Components',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    state: 'componentsCollapse',
    views: [
      {
        path: '/components/buttons',
        name: 'Buttons',
        miniName: 'B',
        component: ComponentButtons,
        layout: 'private',
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardSvg />,
    iconActive: <DashboardActiveSvg />,
    component: Dashboard,
    layout: 'private',
  },
  {
    path: '/regions/:id',
    name: 'Region Details',
    icon: '',
    iconActive: '',
    component: RegionDetails,
    layout: 'private',
  },
  {
    path: '/regions/edit/:id',
    name: 'Edit Region',
    icon: '',
    iconActive: '',
    component: CreateRegion,
    layout: 'private',
  },
  {
    path: '/regions/create-region',
    name: 'Create Region',
    icon: '',
    iconActive: '',
    component: CreateRegion,
    layout: 'private',
  },
  {
    path: '/locations/:id',
    name: 'Location Details',
    icon: '',
    iconActive: '',
    component: LocationDetails,
    layout: 'private',
  },
  {
    path: '/locations/edit/:id',
    name: 'Edit Location',
    icon: '',
    iconActive: '',
    component: CreateLocation,
    layout: 'private',
  },
  {
    path: '/locations/create-location',
    name: 'Create Location',
    icon: '',
    iconActive: '',
    component: CreateLocation,
    layout: 'private',
  },
  {
    path: '/regions',
    name: 'Regions & Locations',
    icon: <RegionsSvg />,
    iconActive: <RegionsActiveSvg />,
    component: Regions,
    layout: 'private',
  },
  {
    path: '/archived',
    name: 'Archived',
    icon: <ArchivedSvg />,
    iconActive: <ArchivedActiveSvg />,
    component: Archived,
    layout: 'private',
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Settings,
    layout: 'private',
  },
  {
    path: '/workflow',
    name: 'Workflow',
    icon: <SettingsSvg />,
    iconActive: <SettingsActiveSvg />,
    component: Workflow,
    layout: 'private',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: 'auth',
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    layout: 'auth',
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    layout: 'auth',
  },
];

export default routes;
