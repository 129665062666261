import * as regionActions from 'src/store/actions/region';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    workflow: useReducerData('region', 'workflow.data.data', {}),
    workflowLoading: useReducerData('workflow', 'workflow.loading', false),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...regionActions,
  });
};
