import cn from 'classnames';
import { LOCATION_DETAILS_TAB_KEYS } from 'src/pages/LocationDetails/constants';
import LocationDetailsTable from '../LocationDetailsTable';
import classes from './TabContent.module.scss';
import { getColumns } from './constants';

const TabContent = ({ className, tabKey }) => {
  const getContent = () => {
    switch (tabKey) {
      case LOCATION_DETAILS_TAB_KEYS.SUB_SYSTEMS: {
        const columns = getColumns();
        return <LocationDetailsTable title="Sub-Systems" columns={columns} />;
      }
      case LOCATION_DETAILS_TAB_KEYS.EQUIPMENT: {
        const columns = getColumns();
        return <LocationDetailsTable title="Equipments" columns={columns} />;
      }
      case LOCATION_DETAILS_TAB_KEYS.EMERGENCY:
        return 'EMERGENCY';
      case LOCATION_DETAILS_TAB_KEYS.STAFF_LIST: {
        const columns = getColumns();
        return <LocationDetailsTable title="Staff List" columns={columns} />;
      }
      case LOCATION_DETAILS_TAB_KEYS.CHEMICALS: {
        const columns = getColumns();
        return <LocationDetailsTable title="Chemicals" columns={columns} />;
      }
      default:
        return '';
    }
  };
  return <div className={cn(classes.wrapper, className)}>{getContent()}</div>;
};

export default TabContent;
