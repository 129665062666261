import cn from 'classnames';
import React from 'react';
import classes from './PageTitle.module.scss';

const PageTitle = ({ className, children, subTitle }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.title}>{children}</div>
      {!!subTitle && <div className={classes.subTitle}>{subTitle}</div>}
    </div>
  );
};

export default PageTitle;
