import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
  //  EdgeProps,
  //  Edge,
} from '@xyflow/react';

const buttonStyle = {
  width: 20,
  height: 20,
  background: '#eee',
  border: '1px solid #fff',
  cursor: 'pointer',
  borderRadius: '50%',
  fontSize: '12px',
  lineHeight: 1,
};

// type ButtonEdgeData = {};

// export type ButtonEdge = Edge<ButtonEdgeData>;

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  source,
  target,
}) {
  const { setEdges, setNodes } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { screenToFlowPosition } = useReactFlow();

  const onEdgeClickDelete = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };
  const onEdgeClickAddNode = (event) => {
    // eslint-disable-next-line no-console
    console.log(event);
    const newNodeId = `${Math.random()}`;
    const newNode = {
      id: newNodeId,
      position: screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      }),
      type: 'Workflow-node',
      data: { label: `?` },
      origin: [0.5, 0.0],
    };

    // @ts-ignore
    setNodes((nds) => nds.concat(newNode));
    setEdges((eds) =>
      eds
        .filter((edge) => edge.id !== id)
        .concat({ id: `${Math.random()}`, source: source, target: newNodeId })
        .concat({ id: `${Math.random()}`, source: newNodeId, target: target })
    );
    // setTimeout(() => setFocusedNode(newNode), 200)
    // setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan edge-tools"
        >
          <button style={buttonStyle} onClick={onEdgeClickDelete}>
            ×
          </button>
          <button style={buttonStyle} onClick={onEdgeClickAddNode}>
            +
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
