/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { WithContext as ReactTags, SEPARATORS } from 'react-tag-input';

const SUGGESTIONS = ['Warning', 'Attention required', 'Ready', 'Overdue'];
const suggestions = [
  {
    id: 'Warning',
    text: 'Warning',
    className: 'bg-danger pl-3 rounded-2',
  },
  {
    id: 'Attention required',
    text: 'Attention required',
    className: 'bg-info pl-3 rounded-2',
  },
  {
    id: 'Ready',
    text: 'Ready',
    className: 'bg-secondary pl-3 rounded-2',
  },
  {
    id: 'Overdue',
    text: 'Overdue',
    className: 'bg-primary pl-3 rounded-2',
  },
];

export const Tags = ({ form, currentForm, runData, formChanged, formTags }) => {
  console.log('flags:');
  console.log(formTags);
  const [tags, setTags] = React.useState(formTags);
  // [
  // { item: {
  //     index: 1,
  //     label: 2,
  //     itemname: 3,
  //     pieces: 4,
  //     weight: 5
  //   },xx:{id: 'Thailand', text: 'Thailand', className: '',item: {
  //     index: 1,
  //     label: 2,
  //     itemname: 3,
  //     pieces: 4,
  //     weight: 5
  //   }} , abc:'abc'},
  // { id: 'warning', text: 'warning', className: 'warning' },
  // { id: 'required', text: 'ready', className: 'bg-info pl-3 rounded-2' },
  // { id: 'ready', text: 'required', className: 'bg-primary pl-3 rounded-2' },
  // ]

  useEffect(() => {
    console.log('updated tags ' + JSON.stringify(tags));
    if (!tags) return;
    formChanged({
      [currentForm + '_FLAGS']: tags?.map((t) => t.text),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const handleDelete = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const onTagUpdate = (index, newTag) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1, newTag);
    setTags(updatedTags);
  };

  const handleAddition = (tag) => {
    setTags((prevTags) => {
      tag.className =
        'pl-3 rounded-2 ' +
        'tag-bg-' +
        tag.text.replace(/ /gi, '-').toLowerCase();
      return [...prevTags, tag];
    });
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const onClearAll = () => {
    setTags([]);
  };

  return (
    <div>
      <ReactTags
        tags={tags}
        suggestions={suggestions}
        separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        handleTagClick={handleTagClick}
        onTagUpdate={onTagUpdate}
        inputFieldPosition="bottom"
        placeholder="Press enter to add a new Flag"
        editable
        clearAll
        onClearAll={onClearAll}
        shouldRenderSuggestions={(e) => e.length > 0}
        renderSuggestion={(item, query) => <div>{item.text}</div>}
        // maxTags={7}
      />
    </div>
  );
};
